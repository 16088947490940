import ModalActionTypes from './modal.types'

const INITIAL_STATE = {
  isVisible: false,
  modalTitle: '',
  modalDescription: '',
  modalIcon: ''
}

const modalReducer = (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case ModalActionTypes.TOGGLE_MODAL_VISIBILITY:
      return {
        ...state,
        isVisible: !state.isVisible
      }

    case ModalActionTypes.SET_MODAL_CONTENT:
      return {
        ...state,
        ...action.payload
      }

    default:
      return state
  }
}

export default modalReducer
