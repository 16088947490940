import { connect } from 'react-redux'
import { compose } from 'redux'

import SubscriptionSteps from './SubscriptionSteps.component'
import WithSpinner from '../WithSpinner/WithSpinner.component'
import { selectIsLoadingFormData } from '../../redux/subscription/subscription.selectors'

const mapStateToProps = (state, ownProps) => ({
  isLoading: selectIsLoadingFormData(state),
  ...ownProps
})

const SubscriptionStepsContainer = compose(
  connect(mapStateToProps),
  WithSpinner
)(SubscriptionSteps)

export default SubscriptionStepsContainer
