import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Form } from 'react-final-form'

import { requiredLength } from '../validations'
import ButtonWithLoader from '../../ButtonWithLoader/ButtonWithLoader.component'
import CustomFormField from '../CustomFormField/CustomFormField.component'
import { toggleOtpSignatureModal, submitOtpSignatureStart } from '../../../redux/subscription/subscription.actions'
import { selectOtpSignatureModalVisibility, selectIsSubmitting } from '../../../redux/subscription/subscription.selectors'

const OtpSignatureModal = ({ currentSigner, visibility, isSubmitting, toggle, submitOtpSignatureStart }) => {
  const onSubmit = values => submitOtpSignatureStart({ values })

  //Patch for reactstrap modal bug
  useEffect(() => {
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  return (
    <Modal isOpen={visibility} toggle={toggle} className="add-customer-code-modal">
      <ModalHeader toggle={toggle}>
        <FontAwesomeIcon icon={['fas', 'signature']} className="icon mr-2" />
        Firma
      </ModalHeader>
      <ModalBody>
        <h3 className="otp-signature-title">
          Indica qui il codice a 6 cifre ricevuto al numero <strong>{currentSigner.mobilePhone}</strong>
        </h3>
        <div className="otp-info otp-signature-info">
          <FontAwesomeIcon icon={['fas', 'info-circle']} />
          <p>La generazione del codice richiede qualche secondo, se non l’hai ancora ricevuto pazienta qualche istante. Grazie</p>
        </div>

        <div className="form-container otp-signature-form">
          <Form
            onSubmit={onSubmit}
            initialValues={{
              signerId: currentSigner.id
            }}
          >
            {({ handleSubmit, form, submitting, pristine, values }) => (
              <form onSubmit={handleSubmit}>
                <CustomFormField
                  id="otpCode"
                  name="otpCode"
                  type="text"
                  validate={requiredLength(6)}
                  maxLength="6"
                  placeholder="Codice OTP di 6 cifre"
                  forcedPlaceholder="000000"
                  className="ab-input"
                  focusOnMount={true}
                  disabled={isSubmitting}
                />

                <div className="form-buttons">
                  <ButtonWithLoader
                    type="submit"
                    otherclasses="ab-button signature-input"
                    buttonLabel="Firma contratto"
                    onClick={handleSubmit}
                    isFetching={isSubmitting}
                    disabled={isSubmitting}
                  />
                </div>
              </form>
            )}
          </Form>
        </div>
      </ModalBody>
    </Modal>
  )
}

const mapStateToProps = createStructuredSelector({
  visibility: selectOtpSignatureModalVisibility,
  isSubmitting: selectIsSubmitting
})

const mapDispatchToProps = dispatch => ({
  toggle: () => dispatch(toggleOtpSignatureModal()),
  submitOtpSignatureStart: values => dispatch(submitOtpSignatureStart(values))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OtpSignatureModal)
