import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Route, Switch } from 'react-router-dom'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { detect } from 'detect-browser'

import './App.scss'
import SubscriptionPage from './pages/SubscriptionPage/SubscriptionPage.component'
import UtilityModal from './components/UtilityModal/UtilityModal.component'
import SubscriptionUtilityModal from './components/SubscriptionUtilityModal/SubscriptionUtilityModal.component'
import UpdateYourBrowserBitch from './components/UpdateYourBrowserBitch/UpdateYourBrowserBitch.component'
import { selectContractClosed } from './redux/subscription/subscription.selectors'
import classNames from 'classnames'

library.add(fab, fas)

function App() {
  //Check browser
  const [ browserCheck, setBrowserCheck ] = useState(true)
  useEffect(() => {
    const { name } = detect()
    if(name === 'ie') setBrowserCheck(false)
  }, [])

  const contractClosed = useSelector(selectContractClosed)

  return (
    <div 
      className={classNames({
        "App": true,
        "contract-closed": contractClosed === true
      })}
      translate="no"
    >
      <UtilityModal />
      {browserCheck === true ?
        <>
          <SubscriptionUtilityModal />
          <Switch>
            <Route
              path="/:contactId?/:secureCode?"
              component={SubscriptionPage}
            />
          </Switch>
        </>
      :
        <UpdateYourBrowserBitch />
      }
    </div>
  )
}

export default App
