import moment from 'moment'
import FormLabels from './formLabels'
import IBAN from 'iban'

export const required = value => value ? undefined : FormLabels.REQUIRED

export const emailCheck = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase()) ? undefined : FormLabels.INVALID_EMAIL
}

export const noFutureDate = value => {
  if(value) {
    const inputValue = moment(value, ['YYYY-MM-DD', 'DD/MM/YYYY'])
    const now = moment()
    return inputValue.diff(now, 'hours') > 0 ? FormLabels.NO_FUTURE_DATE : undefined
  }

  return FormLabels.REQUIRED
}

export const codiceFISCALE = (cfins) =>	{
	const cf = cfins.toUpperCase()
	const cfReg = /^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$/
	if (!cfReg.test(cf))
	{
		return false
	}

	const set1 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ"
	const set2 = "ABCDEFGHIJABCDEFGHIJKLMNOPQRSTUVWXYZ"
	const setpari = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
	const setdisp = "BAKPLCQDREVOSFTGUHMINJWZYX"
	let s = 0
	for( let i = 1; i <= 13; i += 2 )
	  s += setpari.indexOf( set2.charAt( set1.indexOf( cf.charAt(i) )))
	for( let j = 0; j <= 14; j += 2 )
	  s += setdisp.indexOf( set2.charAt( set1.indexOf( cf.charAt(j) )))
	if ( s%26 !== cf.charCodeAt(15)-'A'.charCodeAt(0) )
	  return false
	return true
}

export const vatNumberRegExpCheck = value => {// eslint-disable-next-line
  const re = /^[0-9]{11}$/
  return re.test(String(value).toLowerCase())
}

export const vatNumberAsFiscalCode = value => {
  if(value && (codiceFISCALE(value.toUpperCase()) || vatNumberRegExpCheck(value.toUpperCase()))) {
    return undefined
  }
  else {
    return FormLabels.INVALID_CF
  }
}

export const fiscalCodeCheck = value => {
  if(value && codiceFISCALE(value.toUpperCase())) {
    return undefined
  }
  else {
    return FormLabels.INVALID_CF
  }
}

export const vatNumberCheck = value => {
  if(value && vatNumberRegExpCheck(value)) {
    return undefined
  }
  else {
    return FormLabels.INVALID_VAT
  }
}

export const mustBeAdult = value => {
  if(value) {
    const inputValue = moment(value, ['YYYY-MM-DD', 'DD/MM/YYYY']).format('YYYY-MM-DD')
    return moment().diff(inputValue, 'years') >= 18 ? undefined : FormLabels.ADULT_REQUIRED
  }

  return FormLabels.REQUIRED
}

export const isItalianIban = value => {
  const re = /^IT[a-zA-Z0-9]*$/
  return re.test(String(value.split(' ').join('').toUpperCase()))
}

export const ibanCodeCheck = value => {
  if(value && isItalianIban(value) && IBAN.isValid(value)) {
    return undefined
  }
  else {
    return FormLabels.INVALID_IBAN
  }
}

export const isValidPod = value => {
  const re = /^it.*$/
  if(re.test(String(value).toLowerCase()) && requiredLength(14)(value) === undefined) {
    return undefined
  }
  else {
    return FormLabels.INVALID_POD
  }
}

export const positiveNumber = value => {
  if(value && value > 0) {
    return undefined
  }
  else {
    return FormLabels.POSITIVE_NUMBER
  }
}

export const stringLengthRegExpCheck = (value, length) => {
  let regString = '^([a-zA-Z0-9_-]){*}$'
  regString = regString.replace('*', length)
  let re = new RegExp(regString)
  return re.test(String(value).toLowerCase())
}

export const requiredLength = length => value => {
  if(stringLengthRegExpCheck(value, length)) {
    return undefined
  }
  else {
    return FormLabels.INVALID_STRING_LENGTH.replace('###', length)
  }
}

export const euPhoneCheck = value => {
  if(value && euPhoneRegExpCheck(value)) {
    return undefined
  }
  else {
    return FormLabels.INVALID_PHONE
  }
}

export const euPhoneRegExpCheck = phone => {
  const re = /([^\d+]|^|>)((((\+|00)(\d\d)\s*(\(0\))?)|0)?(\s*\d){7,10})([^\d+]|$|<)$/
  return re.test(String(phone).toLowerCase())
}
