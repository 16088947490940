import { combineReducers } from 'redux'

import subscriptionReducer from './subscription/subscription.reducer'
import modalReducer from './modal/modal.reducer'

const rootReducer = combineReducers({
  subscription: subscriptionReducer,
  modal: modalReducer
})

export default rootReducer
