import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import DomesticReviewContainer from './DomesticReviewContainer.component'
import BusinessReviewContainer from './BusinessReviewContainer.component'
import { fetchContractReviewDataStart } from '../../redux/subscription/subscription.actions'
import { selectContractType, selectError } from '../../redux/subscription/subscription.selectors'

const ReviewStep = ({ contractType, error, fetchContractReviewDataStart }) => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [])

  useEffect(() => {
    fetchContractReviewDataStart()
  }, [fetchContractReviewDataStart])

  return (
    <div className="resume">
      {contractType === 'DOM' && error === null &&
        <DomesticReviewContainer />
      }
      {contractType === 'BUS' && error === null &&
        <BusinessReviewContainer />
      }
    </div>
  )
}

const mapStateToProps = createStructuredSelector({
  contractType: selectContractType,
  error: selectError
})

const mapDispatchToProps = dispatch => ({
  fetchContractReviewDataStart: () => dispatch(fetchContractReviewDataStart())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReviewStep)
