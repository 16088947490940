import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { Form, FormSpy } from 'react-final-form'
import createDecorator from 'final-form-focus'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'reactstrap'

import { required, noFutureDate, fiscalCodeCheck, emailCheck, vatNumberCheck, vatNumberAsFiscalCode } from '../validations'
import {
  selectContactId, selectIsSubmitting, selectFormFieldsValues, selectOngoingLastStepValues,
  selectChangeSupplierDefaultValues, selectAbUser, selectAccessToken, selectFormStep, selectAllowedStep
} from '../../../redux/subscription/subscription.selectors'
import { submitProposalFormStart, updateFormStep, saveOngoingLastStepValues } from '../../../redux/subscription/subscription.actions'
import CustomFormField from '../CustomFormField/CustomFormField.component'
import ExtendedSelectField from '../ExtendedSelectField/ExtendedSelectField.component'
import CustomDatePicker from '../CustomDatePicker/CustomDatePicker.component'
import ExtendedCustomZipCodeSelect from '../ExtendedCustomZipCodeSelect/ExtendedCustomZipCodeSelect.component'
import ExtendedAutocompleteFormField from '../ExtendedAutocompleteFormField/ExtendedAutocompleteFormField.component'
import ButtonWithLoader from '../../ButtonWithLoader/ButtonWithLoader.component'
import { fetchTowns } from '../../../api'
import { defaultBusinessCustomerType, updateFieldValue } from './utils'
import SubscriptionStepsMap from '../../../pages/SubscriptionPage/subscriptionSteps'

//Focus on firt field with an error
const focusOnError = createDecorator()

const BusinessProposalForm = ({ 
  contactId, defaultValues, isSubmitting, formFieldsValues, ongoingLastStepValues,
  abUser, submitProposalForm, accessToken, handleFormStep, saveFormValues,
  formStep, allowedStep
}) => {
  const { name, code } = abUser
  const [ defaultAgentCode, setDefaultAgentCode ] = useState(null)
  const [ defaultUserCode, setDefaulUserCode ] = useState(null)
  useEffect(() => {
    setDefaulUserCode({ value: code, label: name })
    setDefaultAgentCode(code)
  }, [code, name])
  const onSubmit = values => submitProposalForm({ values })

  const formValues = defaultValues || ongoingLastStepValues

  return (
    <Form
      onSubmit={onSubmit}
      decorators={[focusOnError]}
      mutators={{updateFieldValue}}
      initialValues={{
        ...formValues,
        agentCode: defaultAgentCode,
        userCode: defaultUserCode,
        customerType: defaultBusinessCustomerType,
        contactId
      }}
    >
      {({ handleSubmit, form, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit} className="login-form">
          <CustomFormField
            name="agentCode"
            type="hidden"
          />
          <CustomFormField
            name="userCode"
            type="hidden"
          />
          <CustomFormField
            name="customerType"
            type="hidden"
          />
          <ExtendedSelectField
            id="customerToponymId"
            name="customerToponymId"
            options={formFieldsValues.customerToponym}
            validate={required}
            placeholder="Via/Piazza/..."
            className="extended-select"
            classNamePrefix="extended-select"
          />
          <CustomFormField
            id="customerAddress"
            name="customerAddress"
            type="text"
            validate={required}
            placeholder="Indirizzo"
            className="ab-input"
          />
          <CustomFormField
            id="customerStreetNumber"
            name="customerStreetNumber"
            type="text"
            maxLength="4"
            validate={required}
            placeholder="N° civico"
            className="ab-input"
          />
          <ExtendedAutocompleteFormField
            id="customerTownName"
            name="customerTownName"
            className="extended-autocomplete"
            classNamePrefix="extended-autocomplete"
            fieldLabel="Comune"
            placeholder="Comune"
            validate={required}
            fetchFunction={fetchTowns(accessToken)}
            minChars={2}
            maxLength="80"
            getOptionLabel={option => `${option.town} (${option.province})`}
            getOptionValue={option => ({ town: option.town, province: option.province })}
            noOptionsMessage={() => 
              <span className="autocomplete-suggestion">Indica le prime lettere del comune per trovarlo nella lista</span>
            }
          />
          <ExtendedCustomZipCodeSelect
            id="customerPostalCode"
            name="customerPostalCode"
            className="extended-select"
            classNamePrefix="extended-select"
            placeholder="CAP"
            fieldToListen="customerTownName"
            fieldToListenDefaultValue={defaultValues?.customerTownName || null}
            validate={required}
          />
          <CustomFormField
            id="customerFiscalCode"
            name="customerFiscalCode"
            type="text"
            validate={vatNumberAsFiscalCode}
            placeholder="Codice fiscale"
            className="ab-input"
            maxLength="20"
          />
          <CustomFormField
            id="customerVatNumber"
            name="customerVatNumber"
            type="text"
            validate={vatNumberCheck}
            placeholder="P.IVA"
            className="ab-input"
            maxLength="11"
          />
          <CustomFormField
            id="contactPec"
            name="contactPec"
            type="text"
            validate={emailCheck}
            placeholder="Email PEC"
            className="ab-input"
            maxLength="80"
          />

          <section className="form-section">
            <div className="form-header">
              <h3 className="form-title">
                <FontAwesomeIcon icon={['fas', 'balance-scale']} className="icon" />
                Legale rappresentante
              </h3>
            </div>
            <CustomFormField
              id="identityName"
              name="identityName"
              type="text"
              validate={required}
              placeholder="Nome"
              className="ab-input"
              maxLength="80"
            />
            <CustomFormField
              id="identitySurname"
              name="identitySurname"
              type="text"
              validate={required}
              placeholder="Cognome"
              className="ab-input"
              maxLength="80"
            />
            <ExtendedSelectField
              id="customerSex"
              name="customerSex"
              options={formFieldsValues.customerSex}
              validate={required}
              placeholder="Sesso"
              className="extended-select"
              classNamePrefix="extended-select"
            />
            <CustomFormField
              id="identityFiscalCode"
              name="identityFiscalCode"
              type="text"
              validate={fiscalCodeCheck}
              placeholder="Codice fiscale"
              className="ab-input"
              maxLength="20"
            />
            <ExtendedSelectField
              id="identityDocumentType"
              name="identityDocumentType"
              options={formFieldsValues.identityDocumentType}
              validate={required}
              placeholder="Tipo documento d'identità"
              className="extended-select"
              classNamePrefix="extended-select"
            />
            <CustomFormField
              id="identityDocumentNumber"
              name="identityDocumentNumber"
              type="text"
              validate={required}
              placeholder="Numero documento"
              className="ab-input"
              maxLength="20"
            />
            <CustomDatePicker
              className="ab-input form-control"
              id="identityDocumentReleaseDate"
              name="identityDocumentReleaseDate"
              placeholder="Rilasciato il"
              validate={noFutureDate}
              dateFormat="dd/MM/yyyy"
              maxDate={new Date()}
              adjustDateOnChange
            />
            <ExtendedSelectField
              id="identityDocumentEntity"
              name="identityDocumentEntity"
              options={formFieldsValues.identityDocumentEntity}
              validate={required}
              placeholder="Rilasciato da"
              className="extended-select"
              classNamePrefix="extended-select"
            />
          </section>

          <div className="form-buttons">
            <Button 
              className="step-back"
              color="link"
              onClick={() => handleFormStep(SubscriptionStepsMap.CONTACT_STEP)}
            >
              Indietro
            </Button>
            <ButtonWithLoader
              type="submit"
              otherclasses="ab-button"
              buttonLabel="Avanti"
              onClick={handleSubmit}
              isFetching={isSubmitting}
              disabled={isSubmitting}
            />
          </div>
          <FormSpy 
            subscription={{ values: true }}
            onChange={({ values }) => {
              if(allowedStep === formStep) {
                saveFormValues(values)
              }
            }}
          />
        </form>
      )}
    </Form>
  )
}

const mapStateToProps = createStructuredSelector({
  isSubmitting: selectIsSubmitting,
  formFieldsValues: selectFormFieldsValues,
  defaultValues: selectChangeSupplierDefaultValues,
  contactId: selectContactId,
  abUser: selectAbUser,
  accessToken: selectAccessToken,
  ongoingLastStepValues: selectOngoingLastStepValues,
  formStep: selectFormStep,
  allowedStep: selectAllowedStep
})

const mapDispatchToProps = dispatch => ({
  submitProposalForm: data => dispatch(submitProposalFormStart(data)),
  handleFormStep: formStep => dispatch(updateFormStep(formStep)),
  saveFormValues: values => dispatch(saveOngoingLastStepValues(values))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BusinessProposalForm)
