import React from 'react'
import { Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './style.scss'

const ButtonWithLoader = ({ isFetching, buttonLabel = 'Invia', defaultIcon, ...otherProps }) => (
  <Button className={`spinner-btn ${otherProps.otherclasses ? otherProps.otherclasses : ''}`} {...otherProps}>
    {isFetching ?
      <FontAwesomeIcon icon={['fas', 'spinner']} />
    :
      <>
        {defaultIcon &&
          <FontAwesomeIcon icon={defaultIcon} />
        }
      </>
    }
    {buttonLabel}
  </Button>
)

export default ButtonWithLoader
