export const updateFieldValue = ([name, newValue], state, { changeValue }) => {
  changeValue(state, name, () => newValue)
}

export const copyPasteFromSupply = (form, values) => {
  form.mutators.updateFieldValue('toponymIc', values.toponymId)
  form.mutators.updateFieldValue('addressIc', values.streetName)
  form.mutators.updateFieldValue('streetNumberIc', values.streetNo)
  form.mutators.updateFieldValue('townIc', values.townName)
  form.mutators.updateFieldValue('postalCodeIc', values.postalCode)
}

export const copyPasteFromLegal = (form, values) => {
  form.mutators.updateFieldValue('supplyToponym', values.customerToponym)
  form.mutators.updateFieldValue('supplyAddress', values.customerAddress)
  form.mutators.updateFieldValue('supplyStreetNumber', values.customerStreetNumber)
  form.mutators.updateFieldValue('supplyTown', values.customerTown)
  form.mutators.updateFieldValue('supplyPostalCode', values.customerPostalCode)
  form.mutators.updateFieldValue('supplyProvince', values.customerProvince)
}
