import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { fixedSideElementOnScroll, moveSideFromFooter } from './utils'
import {
  selectContractName, selectContractInfo, selectOffersLength, 
  selectPodGreenEnergyResumeBox, selectPdrGreenEnergyResumeBox,
  selectFormStep
} from '../../redux/subscription/subscription.selectors'
import { ReactComponent as PensoInVerdeIcon } from '../../assets/svg/penso-verde.svg'
import classNames from 'classnames'

const ResumeBox = ({ contractInfo, formStep, contractName, offersLength, podGreenEnergy, pdrGreenEnergy }) => {
  const offers = contractInfo ? contractInfo.offers : []

  useEffect(() => {
    const containerElement = document.querySelector('.contract-container')
    const side = document.querySelector('.contract-resume')
    window.addEventListener('scroll', (e) => {
      fixedSideElementOnScroll(containerElement, side)
      moveSideFromFooter()
    })
  }, [])

  useEffect(() => {
    //Set scroll listener for side element
    const side = document.querySelector('.contract-resume')
    //Set container height as side element to preserve scroll when side is fixed
    const sideHeight = side.offsetHeight
    side.parentElement.style.height = `${sideHeight}px`
  }, [offersLength])

  return (
    <div 
      className={classNames({
        "resume-wrapper": true,
        "first-step": formStep === 1
      })}
    >
      <section className="contract-resume">
        <header>
          <div>
            <p>Riepilogo offerta</p>
            <h3 className="text">
              {contractName}
            </h3>
          </div>
        </header>
        <main>
          {offers.map(offer => (
            <div key={offer.code}>
              <section className="resume-section prices">
                <Row className="mx-0 align-items-start">
                  <Col xs="2" className="text-center px-0">
                    {offer.fornitureType === 'E' ? (
                      <FontAwesomeIcon icon={['fas', 'bolt']} className="icon" title={offer.name} />
                    ) : (
                      <FontAwesomeIcon icon={['fas', 'fire']} className="icon" title={offer.name} />
                    )}
                  </Col>
                  <Col xs="10">
                    {offer.costs.map((cost, i) => (
                      <div key={i} className="resume-data-container">
                        <p className="price" key={i}>
                          <span>{cost.slot}</span>
                          <strong>{cost.name} {cost.measure}</strong>
                        </p>
                        {cost.consumptionRange &&
                          <p className="small">{cost.consumptionRange}</p>                    
                        }
                      </div>
                    ))}
                  </Col>
                </Row>
              </section>
              {offer.fornitureType === 'E' && podGreenEnergy === true &&
                <section className="resume-section green-energy">
                  <Row className="mx-0 align-items-start">
                    <Col xs="2" className="text-center px-0">
                      <PensoInVerdeIcon className="icon" />
                    </Col>
                    <Col xs="10">
                      <div className="resume-data-container">
                        <p className="description">
                          Opzione Penso in Verde
                        </p>
                        <p className="small">inclusa nell'offerta</p>
                      </div>
                    </Col>
                  </Row>
                </section>
              }
              {offer.fornitureType === 'G' && pdrGreenEnergy === true &&
                <section className="resume-section green-energy gas">
                  <Row className="mx-0 align-items-center">
                    <Col xs="4" className="text-center px-0">
                      <PensoInVerdeIcon className="icon" />
                    </Col>
                    <Col xs="8" className="px-0">
                      <p className="description">
                        <strong>Opzione Penso in Verde</strong><br />
                        inclusa nell'offerta
                      </p>
                    </Col>
                  </Row>
                </section>
              }
            </div>
          ))}
        </main>
      </section>
    </div>
  )
}

const mapStateToProps = createStructuredSelector({
  contractName: selectContractName,
  contractInfo: selectContractInfo,
  podGreenEnergy: selectPodGreenEnergyResumeBox,
  pdrGreenEnergy: selectPdrGreenEnergyResumeBox,
  offersLength: selectOffersLength,
  formStep: selectFormStep
})

export default connect(mapStateToProps)(ResumeBox)
