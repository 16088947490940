import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { getFormText } from '../../pages/SubscriptionPage/subscriptionSteps'
import { selectFormStep } from '../../redux/subscription/subscription.selectors'
import Timeline from '../Timeline/Timeline.component'
import TimelineNavigationButtons from '../TimelineNavigationButtons/TimelineNavigationButtons.component'

const ContractTitle = ({ formStep, formTitle, formDescription }) => {
  const formText = getFormText(formStep)

  return (
    <section className="contract-title">
      <header>
        <div className="text">
          <h1 className="title animated fadeInDown">
            <span className="rounded-number animated fadeInDown">{formStep}</span>
            <span>{formText.title}</span>
          </h1>
          <p className="description animated fadeInLeft">{formText.description}</p>
        </div>
      </header>
      <main>
        <Timeline />
        <TimelineNavigationButtons />
      </main>
    </section>
  )
}

const mapStateToProps = createStructuredSelector({
  formStep: selectFormStep
})

export default connect(mapStateToProps)(ContractTitle)
