const FormLabels = {
  REQUIRED: "Campo obbligatorio",
  INVALID_EMAIL: "Email non valida!",
  INVALID_PHONE: "Numero di telefono non valido!",
  INVALID_ZIPCODE: "CAP non valido!",
  ADULT_REQUIRED: "Il sottoscrittore del contratto deve essere maggiorenne.",
  INVALID_CF: "Prova a ricontrollare, il formato non sembra corretto.",
  INVALID_VAT: "La partita iva inserita non è valida!",
  INVALID_STRING_LENGTH: "Deve essere composto da ### caratteri, per favore controlla il dato inserito.",
  INVALID_IBAN: "Prova a ricontrollare, il formato non sembra corretto.",
  POSITIVE_NUMBER: "Il valore inserito deve essere un numero positivo, per favore controlla il dato inserito",
  INVALID_DATE: "La data inserita non è nel formato corretto: 24/03/1984 o 1984/03/24",
  NO_FUTURE_DATE: "La data non può essere futura e deve essere nel formato corretto: 24/03/1984 o 1984/03/24",
  INVALID_POD: "Il codice POD deve essere composto da 14 caratteri e iniziare con 'IT'"
}

export default FormLabels
