//!IMPORTANT This component presumes you're using react-final-form
import React from 'react'
import { Field } from 'react-final-form'
import { FormGroup, Input, Label, FormFeedback } from 'reactstrap'

import './style.scss'

const CustomRadioField = ({ children, ...props }) => (
  <FormGroup check className="radio">
    <Field {...props} type="radio">
      {({ input, meta: { error, touched }, description, noValidationMessage, ...otherProps }) => (
        <Label check>
          <Input invalid={error && touched ? true : false} {...input} {...otherProps} />{' '}
          {description}
          {!noValidationMessage &&
            <FormFeedback>{error}</FormFeedback>
          }
        </Label>
      )}
    </Field>
  </FormGroup>
)


export default CustomRadioField
