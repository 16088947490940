import React from 'react'
import ReactLoading from 'react-loading'

import { ReactComponent as Logo } from '../../assets/svg/logo-ab.svg'

const WithSpinner = WrappedComponent => {
  const CustomerSpinner = ({ isLoading, otherClasses, ...otherProps }) => {
    return isLoading ? (
      <section className={`loader${otherClasses ? ` ${otherClasses}` : ''}`}>
        <Logo className="logo" />
        <ReactLoading type="balls" className="loading-icon" />
      </section>
    ) : (
      <WrappedComponent {...otherProps} />
    )
  }

  return CustomerSpinner
}

export default WithSpinner
