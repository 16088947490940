import React, { useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const PdrInfoModal = () => {
  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)

  return (
    <>
      <Button className="info-button" onClick={toggle}>
        <FontAwesomeIcon icon={['fas', 'question-circle']} className="icon" />
      </Button>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Cosa è il codice PDR?</ModalHeader>
        <ModalBody>
          Il codice PDR (ovvero il Punto di Riconsegna) è il codice che identifica la fornitura di gas.
          È composto da 14 caratteri numerici e non cambia mai, rimane lo stesso anche se si cambia fornitore.
        </ModalBody>
      </Modal>
    </>
  )
}

export default PdrInfoModal
