import React from 'react'
import { Field } from 'react-final-form'
import { FormGroup, FormFeedback, Label } from 'reactstrap'
import DatePicker, { registerLocale } from "react-datepicker"
import it from 'date-fns/locale/it'
import "react-datepicker/dist/react-datepicker.css"
import classnames from 'classnames'

import { required } from '../validations'

registerLocale('it', it)
const handleSelection = value => {
  const d = new Date(value)
  const ye = new Intl.DateTimeFormat('it', { year: 'numeric' }).format(d)
  const mo = new Intl.DateTimeFormat('it', { month: '2-digit' }).format(d)
  const da = new Intl.DateTimeFormat('it', { day: '2-digit' }).format(d)
  const parsedDate = Date.UTC(ye, mo - 1, da, '00', '00')
  return new Date(parsedDate)
}

const DatePickerAdapter = ({ input: { onChange, value, onBlur, name }, meta: { error, touched }, className, placeholder, ...rest }) => (
  <div className={`${value ? 'shrink ' : ''}custom-datepicker`}>
    <DatePicker
      className={`${className} ${error && touched ? 'is-invalid' : '' }`}
      locale="it"
      selected={value ? handleSelection(value) : null}
      onChange={date => {onChange(handleSelection(date)); onBlur();}}
      onBlur={onBlur}
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
      {...rest}
    >
    </DatePicker>
    {placeholder &&
      <Label for={name} className={`${value.length ? 'shrink' : ''} form-input-label`}>{placeholder}</Label>
    }
    <FormFeedback className={classnames({ 'd-flex': error && touched })}>{error}</FormFeedback>
  </div>
)

const CustomDatePicker = (props) => (
  <FormGroup>
    <Field
      validate={required}
      component={DatePickerAdapter}
      {...props}
    />
  </FormGroup>
)

export default CustomDatePicker
