import { getAPIUrl, abAuth } from '../../api'
import SubscriptionStepsMap from '../../pages/SubscriptionPage/subscriptionSteps'

export const FormStepIdToName = {
  1: "contactFrom",
  2: "proposalForm",
  3: "paymentForm",
  4: "cadastralForm",
  5: "supplyPointsForm"
}

export function updateResumeBox(state) {
  const { contractInfo: { offers } } = state
  const { defaultValues: { supplyPoints } } = state

  if(supplyPoints) {
    const podOffer = supplyPoints.podContractPriceListCode && supplyPoints.podContractPriceListCode !== null ? 1 : 0
    const pdrOffer = supplyPoints.pdrContractPriceListCode && supplyPoints.pdrContractPriceListCode !== null ? 1 : 0
    const offersCount = podOffer + pdrOffer
    if(offersCount < offers.length) {
      return removeContract(state)
    } else {
      return addContract(state)
    }
  }

  return offers
}

export function updateSignersData({ values: { signerId, mobilePhone } }, state) {
  const { signersData } = state
  const updatedSignersData = signersData.map(signer => {
    if(signer.id === signerId) signer.mobilePhone = mobilePhone
    return signer
  })

  return updatedSignersData
}

export function getDefaultFormStep(defaultEntitiesId) {
  const { podId, pdrId, cadastralDataId, bankAccountDetailId, contractId, contactId } = defaultEntitiesId
  if(podId || pdrId) return SubscriptionStepsMap.OTP_STEP
  if(cadastralDataId) return SubscriptionStepsMap.SUPPLY_POINTS_STEP
  if(bankAccountDetailId) return SubscriptionStepsMap.CADASTRAL_STEP
  if(contractId) return SubscriptionStepsMap.PAYMENT_STEP
  if(contactId) return SubscriptionStepsMap.PROPOSAL_STEP

  return SubscriptionStepsMap.CONTACT_STEP
}

export const setContractOffersForUpdate = payload => {
  const { contractInfo: { offers, applyTo }, defaultEntitiesId: { podId, pdrId } } = payload
  if(podId) {
    const energyItems = offers.filter(offer => offer.fornitureType === 'E')
    if(!energyItems.length) offers.push(applyTo)
  }

  if(pdrId) {
    const gasItems = offers.filter(offer => offer.fornitureType === 'G')
    if(!gasItems.length) offers.push(applyTo)
  }

  return offers
}

export const setInitialGreenEnergy = (type, payload) => {
  const { contractInfo: { offers, applyTo } } = payload
  const offerTypes = offers.filter(offer => offer.fornitureType === type)
  if(offerTypes.length) return offerTypes[0].greenEnergy !== null ? offerTypes[0].greenEnergy : null
  return applyTo && applyTo.greenEnergy !== null ? applyTo.greenEnergy : null
}

export const addContract = state => {
  const { contractInfo: { offers, applyTo } } = state
  offers.push(applyTo)
  return offers
}

export function addContractGreenEnergy(type, state) {
  let { contractInfo: { podGreenEnergy, pdrGreenEnergy } } = state
  const { defaultValues: { supplyPoints } } = state
  if(type === 'E') {
    if(supplyPoints && supplyPoints.podGreenEnergy) {
      podGreenEnergy = supplyPoints.podGreenEnergy
    }
    return podGreenEnergy
  }
  if(type === 'G') {
    if(supplyPoints && supplyPoints.pdrGreenEnergy) {
      pdrGreenEnergy = supplyPoints.pdrGreenEnergy
    }
    return pdrGreenEnergy
  }
}

export const removeContract = state => {
  const { contractInfo: { offers, applyTo } } = state
  const updatedOffers = offers.filter(offer => offer.code !== applyTo.code)
  return updatedOffers
}

//Set up data for form default values
export function setupPodFormData(values) {
  const podData = {
    ...values,
    podNo: values.no,
    podContractPriceListCode: values.contractPriceListCode,
    podIsResident: parseInt(values.isResident?.value) === 1 ? true : false,
    billingShippingAddress: parseInt(values.billingShippingAddress?.value) === 3 ? false : true
  }

  return podData
}

export function setupPdrFormData(values) {
  const pdrData = {
    ...values,
      pdrNo: values.no,
      pdrContractPriceListCode: values.contractPriceListCode,
      pdrIsResident: parseInt(values.isResident?.value) === 1 ? true : false,
      billingShippingAddress: parseInt(values.billingShippingAddress?.value) === 3 ? false : true
    }

  return pdrData
}

//Set up data for POST submit
export function setupPodData(values) {
  const podData = {
    ...values,
    no: values.podNo,
    contractPriceListCode: values.podContractPriceListCode,
    isResident: values.podIsResident === true ? { value: 1, label: "Sì" } : { value: 2, label: "No" },
    billingShippingAddress: values.billingShippingAddress === true ? { value: 1, label: "No" } : { value: 3, label: "Sì" },
  }

  return podData
}

export function setupPdrData(values) {
  const pdrData = {
    ...values,
    no: values.pdrNo,
    isResident: values.pdrIsResident === true ? { value: 1, label: "Sì" } : { value: 2, label: "No" },
    contractPriceListCode: values.pdrContractPriceListCode,
    billingShippingAddress: values.billingShippingAddress === true ? { value: 1, label: "No" } : { value: 3, label: "Sì" },
  }

  return pdrData
}

export function* fetchData(fetchUrl, token = abAuth, includeBearer = false) {
  const fetchPromise = yield fetch(
    fetchUrl,
    {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': includeBearer ? `Bearer ${token}` : token
      }
    }
  )
  .then(handleErrors)
  .then(response => {
    const contentType = response.headers.get("content-type")
    if(contentType && contentType.includes("application/json")) {
      return response.json()
    }
    return response
  })

  return fetchPromise
}

export function* submitForm(fetchUrl, method, data, accessToken) {
  const fetchPromise = yield fetch(
    fetchUrl,
    {
      method: method,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Cross-Origin-Embedder-Policy': 'require-corp',
        'Cross-Origin-Opener-Policy': 'same-origin',
        'Authorization': `Bearer ${accessToken}`
      },
      body: JSON.stringify(data)
    }
  )
  .then(handleErrors)
  .then(response => {
    const contentType = response.headers.get("content-type")
    if(contentType && contentType.includes("application/json")) {
      return response.json()
    }
    return response
  })

  return fetchPromise
}

export function parseSystemError(errorCode) {
  switch(errorCode) {
    case 404:
      return errorMessages.NOT_FOUND_ERROR

    case 401:
      return errorMessages.NOT_AUTHORIZED_ERROR

    case 500:
      return errorMessages.SERVER_ERROR

    case 503:
      return errorMessages.SERVER_UNVAILABLE

    case 504:
      return errorMessages.SERVER_TIMEOUT

    default:
      return errorMessages.DEFAULT_ERROR
  }
}

export function handleErrors(response) {
  const contentType = response.headers.get("content-type")
  if (!response.ok) {
    if(contentType && contentType.includes("application/json")) {
      const json = response.json()
      return json.then(err => {
        throw new Error(err.error?.message) || errorMessages.DEFAULT_ERROR
      })
    }
    const errorMessage = parseSystemError(response.status)
    throw new Error(errorMessage)
  }

  return response
}

export function* getFormFieldsValues(accessToken, e, g) {
  let codes = []
  if(e !== undefined) {
    codes.push(e)
  }
  if(g !== undefined) {
    codes.push(g)
  }
  const fetchPromise = yield fetch(
    `${getAPIUrl('abStorePortal')}/form-items/funnel?offerCodes=${codes.join(',')}`,
    {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Cross-Origin-Embedder-Policy': 'require-corp',
        'Cross-Origin-Opener-Policy': 'same-origin',
        'Authorization': `Bearer ${accessToken}`
      }
    }
  )
  .then(response => {
    const contentType = response.headers.get("content-type")
    if(contentType && contentType.includes("application/json")) {
      return response.json()
    }
    return response
  })
  .catch(error => { throw new Error(error) })

  return fetchPromise
}

export function* fetchContract(accessToken, e, g) {
  let codes = []
  if(e !== undefined) {
    codes.push(e)
  }
  if(g !== undefined) {
    codes.push(g)
  }
  const fetchPromise = yield fetch(
    `${getAPIUrl('abStorePortal')}/form-items/offers-details?offerCodes=${codes.join(',')}`,
    {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Cross-Origin-Embedder-Policy': 'require-corp',
        'Cross-Origin-Opener-Policy': 'same-origin',
        'Authorization': `Bearer ${accessToken}`
      }
    }
  )
  .then(response => {
    const contentType = response.headers.get("content-type")
    if(contentType && contentType.includes("application/json")) {
      return response.json()
    }
    return response
  })
  .catch(error => { throw new Error(error) })

  return fetchPromise
}

export function gaLog(eventAction, selectedStep) {
  return new Promise((resolve, reject) => {
    window.dataLayer &&
    window.dataLayer.push({
      'event' : 'ga_event_funnel', 'event_category' : 'funnel', 'event_action' : [eventAction], 'event_label' : [`step_${selectedStep}`]
    })
    
    resolve()
  })
}

export const errorMessages = {
  DEFAULT_ERROR: "Si è verificato un problema, per favore riprovare ad effettuare l'operazione.",
  NOT_FOUND_ERROR: "L'indirizzo che stai cercando di raggiungere non è stato trovato.",
  NOT_AUTHORIZED_ERROR: "Non sei autorizzato ad effettuare questa operazione.",
  SERVER_ERROR: "Si è verificato un problema sul server durante l'esecuzione della richiesta. Per favore riprova tra poco.",
  SERVER_UNVAILABLE: "Il server non è al momento raggiungibile. Per favore riprova tra poco.",
  SERVER_TIMEOUT: "Il server non risponde. Per favore riprova tra poco.",
  INTERNET_DISCONNECTED: "Connessione assente: verifica di essere collegato ad internet."
}
