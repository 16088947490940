import styled from 'styled-components'

const ExtendedAutocompleteFormFieldStyle = styled.div`
  .extended-autocomplete {
    outline: none !important;
  }

  .extended-autocomplete__control {
    border: 1px solid #0065a3;
    outline: none !important;
    border-radius: 5px;
    box-shadow: none;

    &:focus,
    &:hover {
      border-color: #0065a3;
    }
    &.extended-autocomplete__control--is-focused {
      box-shadow: 0 0 0 1px #0065a3;
    }
  }

  .extended-autocomplete__value-container {
    padding: .55rem 1rem;
    font-size: .85rem;
  }

  .extended-autocomplete__input {
    color: #0065a3;
  }

  .extended-autocomplete__single-value,
  .extended-autocomplete__placeholder {
    font-size: .85rem;
    font-weight: 500;
    color: #0065a3;
  }

  .extended-autocomplete__placeholder {
    color: rgba(0, 101, 163, .5);
  }

  .extended-autocomplete__indicator {
    color: #0065a3;
  }

  .extended-autocomplete__indicator-separator {
    background-color: #ddd;
  }

  .extended-autocomplete__option {
    font-size: .85rem;
    color: #0065a3;

    &.extended-autocomplete__option--is-selected {
      background-color: rgba(0, 101, 163, .5);
      color: #fff;
    }

    &.extended-autocomplete__option--is-focused {
      background-color: #0065a3;
      color: #fff;
    }
  }

  .form-input-label {
    z-index: -1;
  }
`

export default ExtendedAutocompleteFormFieldStyle
