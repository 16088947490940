import React, { useState, useEffect, useCallback } from 'react'
import { Field } from 'react-final-form'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import { FormGroup, FormFeedback, Label } from 'reactstrap'
import { OnChange } from 'react-final-form-listeners'
import classnames from 'classnames'

import { fetchData, debounce } from './utils'
import { getAPIUrl } from '../../../api'
import { selectAccessToken } from '../../../redux/subscription/subscription.selectors'
import ExtendedCustomZipCodeSelectStyle from './style'

const ExtendedCustomZipCodeSelect = ({ fieldToListen, fieldToListenDefaultValue, ...props }) => {
  const [ options, setOptions ] = useState([])
  const accessToken = useSelector(selectAccessToken)

  const updateValues = useCallback(value => {
    fetchData(
      `${getAPIUrl('abStorePortal')}/form-items/postal-codes?townName=${value.town}`,
      accessToken
    )
    .then(({ data }) => setOptions(data.map(value => ({ value: value, label: value }))))
    .catch(error => console.error(error.message))
  }, [accessToken])

  useEffect(() => {
    if(fieldToListenDefaultValue) updateValues(fieldToListenDefaultValue)
  }, [fieldToListenDefaultValue, updateValues])

  return (
    <ExtendedCustomZipCodeSelectStyle>
      <FormGroup>
        <Field {...props}>
          {({ isMulti, placeholder, fieldDescription, noValidationMessage, input: { name, value, onChange, onBlur }, meta: { error, touched }, className, ...input }) => (
            <>
              <Select
                className={`${className}${error && touched ? ' is-invalid' : (!error && touched ? ' is-valid' : '')}`}
                value={value}
                isMulti={isMulti}
                options={options}
                onChange={value => onChange(value)}
                onBlur={onBlur}
                placeholder={placeholder}
                {...input}
              />
              {placeholder &&
                <Label for={input.name} className={`${value ? 'shrink' : ''} form-input-label`}>{placeholder}</Label>
              }
              <FormFeedback className={classnames({ 'd-flex': error && touched })}>{error}</FormFeedback>
              <OnChange name={fieldToListen}>
                {debounce(value => {
                  if(value?.town && value.town.length >= 2) {
                    updateValues(value)
                  }
                }, 300)}
              </OnChange>
            </>
          )}
        </Field>
      </FormGroup>
    </ExtendedCustomZipCodeSelectStyle>
  )
}

export default ExtendedCustomZipCodeSelect
