import React from 'react'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import { carouselItems } from './utils'

const ReassuranceSlider = () => {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    draggable: false,
    swipe: false,
    speed: 500,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1
  }

  return (
    <Slider 
      {...settings}
    >
      {carouselItems.map(item => (
        <div 
          className="slide-item"
          key={item.key}  
        >
          <h6>{item.caption}</h6>
        </div>
      ))}
    </Slider>
  )
}

export default ReassuranceSlider