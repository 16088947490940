import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { Progress, Button, Tooltip } from 'reactstrap'
import classnames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'

import SubscriptionStepsMap, { getRemainingTime, getStepTitle } from '../../pages/SubscriptionPage/subscriptionSteps'
import { updateFormStep, toggleTooltip } from '../../redux/subscription/subscription.actions'
import { selectFormStep, selectAllowedStep, selectShowTooltip } from '../../redux/subscription/subscription.selectors'

const STEPS_NUMBER = Object.keys(SubscriptionStepsMap).length

const Timeline = ({ allowedStep, formStep, contractEditable, showTooltip, handleFormStep, toggleTooltip }) => {
  const handleStepClick = step => (step !== formStep ? handleFormStep(step) : false)

  return(
    <section className="timeline" id="TooltipExample">
      <div className="time-memo-container">
        <div
          className={`runner step-${formStep} animated fadeInUp delay-1`}
        >
          <FontAwesomeIcon icon={['fas', 'running']} className="mr-1 icon" />
          <span>{getRemainingTime(formStep)}</span>
        </div>
        <div className="time-memo"></div>
        <div className="time-memo"></div>
        <div className="time-memo"></div>
        <div className="time-memo"></div>
        <div className="time-memo"></div>
        <div className="time-memo"></div>
        <div className="time-memo  animated fadeInUp delay-2">
          <FontAwesomeIcon icon={['fas', 'flag-checkered']} className="icon mr-1" />
        </div>
      </div>
      <Progress multi className="animated fadeInRight" value={formStep} max="100">
        {_.map(SubscriptionStepsMap, (step, i) => (
          <Progress
            key={i}
            bar
            value={formStep * (100 / STEPS_NUMBER)}
            variant="success"
            className={classnames({
              'active': formStep >= step,
              'last-active-step': formStep === step,
              'editable': contractEditable && formStep >= step
            })}
          >
            <Button
              color="link"
              className="step-link"
              disabled={allowedStep < step}
              onClick={() => handleStepClick(step)}
              id={classnames({
                'step-tooltip': step === SubscriptionStepsMap.CONTACT_STEP
              })}
            >
              {step} - {getStepTitle(step)}
            </Button>
          </Progress>
        ))}
        {showTooltip &&
          <Tooltip className="timeline-tooltip" placement="bottom" target="step-tooltip" isOpen={showTooltip} onClick={toggleTooltip}>
            <button className="close" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
            <span>Cliccando su uno step completato puoi modificare i dati precedentemente inseriti.</span>
          </Tooltip>
        }
      </Progress>
    </section>
  )
}

const mapStateToProps = createStructuredSelector({
  formStep: selectFormStep,
  allowedStep: selectAllowedStep,
  showTooltip: selectShowTooltip
})

const mapDispatchToProps = dispatch => ({
  handleFormStep: formStep => dispatch(updateFormStep(formStep)),
  toggleTooltip: () => dispatch(toggleTooltip())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Timeline)
