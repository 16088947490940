import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { selectContractType } from '../../../redux/subscription/subscription.selectors'
import { fetchContactDefaultValuesStart } from '../../../redux/subscription/subscription.actions'
import DomesticContactForm from './DomesticContactForm.component'
import BusinessContactForm from './BusinessContactForm.component'
import ReminderToast from './ReminderToast.component'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ContactForm = ({ contractType, fetchContactDefaultValues }) => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [])

  return (
    <div className="form-container">
      <ReminderToast />
      <p className="mandatory-fields">
        <FontAwesomeIcon icon={['fas', 'info-circle']} />
        Tutti i campi sono obbligatori
      </p>
      {contractType === 'DOM' &&
        <DomesticContactForm />
      }
      {contractType === 'BUS' &&
        <BusinessContactForm />
      }
    </div>
  )
}

const mapStateToProps = createStructuredSelector({
  contractType: selectContractType
})

const mapDispatchToProps = dispatch => ({
  fetchContactDefaultValues: data => dispatch(fetchContactDefaultValuesStart(data))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactForm)
