import { createSelector } from 'reselect'
import _ from 'lodash'

import SubscriptionStepsMap from '../../pages/SubscriptionPage/subscriptionSteps'
import { FormStepIdToName } from './subscription.utils'

const selectSubscription = state => state.subscription

export const selectAccessToken = createSelector(
  [selectSubscription],
  subscription => subscription.accessToken || null
)

export const selectFirebaseUser = createSelector(
  [selectSubscription],
  subscription => subscription.firebaseUser || null
)

export const selectAbUser = createSelector(
  [selectSubscription],
  subscription => subscription.abUser || null
)

export const selectError = createSelector(
  [selectSubscription],
  subscription => subscription.error
)

export const selectIsLoadingFormData = createSelector(
  [selectSubscription],
  subscription => subscription.contractInfo === null || subscription.formFieldsValues === null ? true : false
)

export const selectIsFetchingContractData = createSelector(
  [selectSubscription],
  subscription => (subscription.isLoading || subscription.reviewData === null ? true : false)
)

export const selectDefaultEntitiesId = createSelector(
  [selectSubscription],
  subscription => subscription.defaultEntitiesId
)

export const selectContactId = createSelector(
  [selectDefaultEntitiesId],
  defaultEntitiesId => (defaultEntitiesId ? defaultEntitiesId.contactId : null)
)

export const selectContractId = createSelector(
  [selectDefaultEntitiesId],
  defaultEntitiesId => (defaultEntitiesId ? defaultEntitiesId.contractId : null)
)

export const selectBankAccountDetailId = createSelector(
  [selectDefaultEntitiesId],
  defaultEntitiesId => (defaultEntitiesId ? defaultEntitiesId.bankAccountDetailId : null)
)

export const selectCadastralDataId = createSelector(
  [selectDefaultEntitiesId],
  defaultEntitiesId => (defaultEntitiesId ? defaultEntitiesId.cadastralDataId : null)
)

export const selectPodId = createSelector(
  [selectDefaultEntitiesId],
  defaultEntitiesId => (defaultEntitiesId ? defaultEntitiesId.podId : null)
)

export const selectPdrId = createSelector(
  [selectDefaultEntitiesId],
  defaultEntitiesId => (defaultEntitiesId ? defaultEntitiesId.pdrId : null)
)

export const selectIsFetchingSignersData = createSelector(
  [selectSubscription],
  subscription => subscription.isLoading
)

export const selectFormStep = createSelector(
  [selectSubscription],
  subscription => subscription.formStep
)

export const selectAllowedStep = createSelector(
  [selectSubscription],
  subscription => subscription.allowedStep
)

export const selectContractType = createSelector(
  [selectSubscription],
  subscription => subscription.contractInfo ? subscription.contractInfo.offers[0].customerType : null
)

export const selectContractPdf = createSelector(
  [selectSubscription],
  subscription => subscription.contractPdf
)

export const selectIsSubmitting = createSelector(
  [selectSubscription],
  subscription => subscription.isSubmitting
)

export const selectContractInfo = createSelector(
  [selectSubscription],
  subscription => subscription.contractInfo
)

export const selectPodGreenEnergyResumeBox = createSelector(
  [selectContractInfo],
  contractInfo => contractInfo.podGreenEnergy
)

export const selectPdrGreenEnergyResumeBox = createSelector(
  [selectContractInfo],
  contractInfo => contractInfo.pdrGreenEnergy
)

export const selectContractName = createSelector(
  [selectContractInfo],
  contractInfo => {
    if(contractInfo) {
      const { offers } = contractInfo
      const offersTitles = offers.map(offer => offer.funnelName)
      return offersTitles.join(' + ')
    }
    return ''
  }
)

export const selectFormFieldsValues = createSelector(
  [selectSubscription],
  subscription => subscription.formFieldsValues
)

export const selectDefaultValues = createSelector(
  [selectSubscription],
  subscription => subscription.defaultValues
)

export const selectContactDefaultValues = createSelector(
  [selectDefaultValues],
  defaultValues => (defaultValues.contact ? defaultValues.contact : null)
)

export const selectChangeSupplierDefaultValues = createSelector(
  [selectDefaultValues],
  defaultValues => (defaultValues.changeSupplier ? defaultValues.changeSupplier : null)
)

export const selectBankAccountDefaultValues = createSelector(
  [selectDefaultValues],
  defaultValues => (defaultValues.bankAccountDetail ? defaultValues.bankAccountDetail : null)
)

export const selectCadastralDataDefaultValues = createSelector(
  [selectDefaultValues],
  defaultValues => (defaultValues.cadastralData ? defaultValues.cadastralData : null)
)

export const selectSupplyPointsDefaultValues = createSelector(
  [selectDefaultValues],
  defaultValues => (defaultValues.supplyPoints ? defaultValues.supplyPoints : null)
)

export const selectEnergyOffer = createSelector(
  [selectContractInfo],
  contractInfo => {
    const { offers } = contractInfo
    return _.head(_.take(offers.filter(offer => offer.fornitureType === 'E'), 1))
  }
)

export const selectGasOffer = createSelector(
  [selectContractInfo],
  contractInfo => {
    const { offers } = contractInfo
    return _.head(_.take(offers.filter(offer => offer.fornitureType === 'G'), 1))
  }
)

export const selectAddOnContract = createSelector(
  [selectContractInfo],
  contractInfo => contractInfo.applyTo ? contractInfo.applyTo : null
)

export const selectOffersLength = createSelector(
  [selectContractInfo],
  contractInfo => contractInfo.offers.length
)

export const selectReviewData = createSelector(
  [selectSubscription],
  subscription => subscription.reviewData
)

export const selectSignersData = createSelector(
  [selectSubscription],
  subscription => subscription.signersData
)

export const selectCurrentSigner = createSelector(
  [selectSignersData],
  signersData => _.head(_.take(signersData.filter(signer => signer.signed === false), 1))
)

export const selectAlreadySignedData = createSelector(
  [selectSignersData],
  signersData => signersData.filter(signer => signer.signed === true)
)

export const selectUtilityModal = createSelector(
  [selectSubscription],
  subscription => subscription.utilityModal
)

export const selectOtpSignatureModalVisibility = createSelector(
  [selectSubscription],
  subscription => subscription.otpSignatureModalVisibility
)

export const selectContractClosed = createSelector(
  [selectIsLoadingFormData, selectCurrentSigner, selectAlreadySignedData],
  (isLoadingFormData, currentSigner, alreadySignedData) => (
    isLoadingFormData === false &&
    currentSigner === undefined &&
    alreadySignedData.length ? true : false
  )
)

export const selectShowTooltip = createSelector(
  [selectSubscription, selectDefaultEntitiesId],
  (subscription, defaultEntitiesId) => (
    subscription.tooltipVisible === null &&
    subscription.formStep === SubscriptionStepsMap.PROPOSAL_STEP &&
    !defaultEntitiesId.bankAccountDetailId ? true : subscription.tooltipVisible
  )
)

export const selectIsFetchingContactData = createSelector(
  [selectSubscription, selectContactDefaultValues, selectContactId],
  (subscription, contractDefaultValues, contactId) => subscription.isLoading || (contractDefaultValues === null && contactId) ? (
    true
  ) : (
    false
  )
)

export const selectIsFetchingProposalData = createSelector(
  [selectSubscription, selectChangeSupplierDefaultValues, selectContractId],
  (subscription, changeSupplierDefaultValues, contractId) => subscription.isLoading || (changeSupplierDefaultValues === null && contractId) ? (
    true
  ) : (
    false
  )
)

export const selectIsFetchingPaymentData = createSelector(
  [selectSubscription, selectBankAccountDefaultValues, selectBankAccountDetailId],
  (subscription, bankAccountDefaultValues, bankAccountDetailId) => subscription.isLoading || (bankAccountDefaultValues === null && bankAccountDetailId) ? (
    true
  ) : (
    false
  )
)

export const selectIsFetchingCadastralData = createSelector(
  [selectSubscription, selectCadastralDataDefaultValues, selectCadastralDataId],
  (subscription, cadastralDefaultValues, cadastralDataId) => subscription.isLoading || (cadastralDefaultValues === null && cadastralDataId) ? (
    true
  ) : (
    false
  )
)

export const selectIsFetchingSupplyPointsData = createSelector(
  [selectSubscription, selectSupplyPointsDefaultValues, selectPodId, selectPdrId],
  (subscription, supplyPointsDefaultValues, podId, pdrId) => subscription.isLoading || (supplyPointsDefaultValues === null && (podId || pdrId))  ? (
    true
  ) : (
    false
  )
)

export const selectOngoingLastStepValues = createSelector(
  [selectSubscription],
  subscription => subscription.ongoingLastStepValues
)

export const selectOngoingStepValues = createSelector(
  [selectSubscription, selectFormStep],
  (subscription, formStep) => subscription.ongoingLastStepValues && subscription.ongoingLastStepValues[FormStepIdToName[formStep]] ? (
    subscription.ongoingLastStepValues[FormStepIdToName[formStep]]
  ) : (
    null
  )
)