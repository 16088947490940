import confJson from './configuration.json'

//The API url memoized
const getRootUrl = () => {
  let cache = {}
  return (key) => {
    if (key in cache) {
      return cache[key]
    }
    else {
      const { endPoints, env } = confJson
      const { protocol, host, port, prefix, version } = endPoints[key][env]

      let apiUrl = protocol + '://' + host
      apiUrl = port ? `${apiUrl}:${port}` : apiUrl
      apiUrl = prefix ? `${apiUrl}/${prefix}` : apiUrl
      apiUrl = version ? `${apiUrl}/v${version}` : apiUrl
      cache[key] = apiUrl
      return apiUrl
    }
  }
}

const rootUrl = getRootUrl()

export const getAPIUrl = (endPoint = "abServices", route = undefined) => {
  const apiUrl = rootUrl(endPoint)
  return apiUrl
}

export const abAuth = "qk65p4hh5ErHFKlvZp5wrcgH2oRmiSnYtnbcLsHY9T9pdeJpcT9W6gU5YMrADvQ2"

export const getAPIToken = () => {
  return confJson.apiToken
}

export const fetchTowns = accessToken => searchedValue => {
  if(searchedValue.length > 1) {
    const fetchPromise = fetch(
      `${getAPIUrl('abStorePortal')}/form-items/towns/small-info?townName=${searchedValue}`,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Cross-Origin-Embedder-Policy': 'require-corp',
          'Cross-Origin-Opener-Policy': 'same-origin',
          Authorization: `Bearer ${accessToken}`
        }
      }
    )
    .then(response => {
      const contentType = response.headers.get("content-type")
      if(contentType && contentType.includes("application/json")) {
        return response.json()
      }
      return response
    })
    .then(({ data }) => data)
    .catch(error => { throw new Error(error) })

    return fetchPromise
  }
}

export const fetchVendors = accessToken => searchedValue => {
  if(searchedValue.length > 1) {
    const fetchPromise = fetch(
      `${getAPIUrl('abStorePortal')}/change-suppliers/pdrs/vendors?vendorName=${searchedValue}&limit=20`,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Cross-Origin-Embedder-Policy': 'require-corp',
          'Cross-Origin-Opener-Policy': 'same-origin',
          Authorization: `Bearer ${accessToken}`
        }
      }
    )
    .then(response => {
      const contentType = response.headers.get("content-type")
      if(contentType && contentType.includes("application/json")) {
        return response.json()
      }
      return response
    })
    .then(({ data }) => data)
    .catch(error => { throw new Error(error) })

    return fetchPromise
  }
}
