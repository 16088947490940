export const getRemainingTime = formStep => {
  switch(formStep) {
    case 2:
      return '4 min. al traguardo!'

    case 3:
      return '3 min. al traguardo!'

    case 4:
      return '2 min. al traguardo!'

    case 5:
      return '1 min. al traguardo!'

    case 6:
      return 'Ci sei quasi!'

    default:
      return '5 min. al traguardo!'
  }
}

const contractInfo = [
  {
    title: "Dati contatto",
    description: "Inserisci le tue informazioni di contatto"
  },
  {
    title: "Dati titolare",
    description: "Compila con i dati dell'intestatario della fornitura"
  },
  {
    title: "Dati pagamento",
    description: "Indica le tue coordinate bancarie"
  },
  {
    title: "Dati recapito",
    description: "Inserisci le informazioni relative al punto di fornitura"
  },
  {
    title: "Dati fornitura",
    description: "Inserisci le informazioni della fornitura"
  },
  {
    title: "Riepilogo",
    description: "Controlla i tuoi dati un'ultima volta"
  },
  {
    title: "Firma",
    description: "Firma il contratto ed entra nel mondo ABenergie!"
  }
]

export const getStepTitle = (formStep) => contractInfo[formStep - 1].title
export const getFormText = formStep => contractInfo[formStep - 1]

const SubscriptionStepsMap = {
  CONTACT_STEP: 1,
  PROPOSAL_STEP: 2,
  PAYMENT_STEP: 3,
  CADASTRAL_STEP: 4,
  SUPPLY_POINTS_STEP: 5,
  REVIEW_STEP: 6,
  OTP_STEP: 7
}

export default SubscriptionStepsMap
