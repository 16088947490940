import ModalActionTypes from './modal.types'

export const toggleModalVisibility = () => ({
  type: ModalActionTypes.TOGGLE_MODAL_VISIBILITY
})

export const setModalContent = modalContent => ({
  type: ModalActionTypes.SET_MODAL_CONTENT,
  payload: modalContent
})
