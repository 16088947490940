import SubscriptionTypes from './subscription.types'

export const addContract = () => ({
  type: SubscriptionTypes.ADD_CONTRACT
})

export const removeContract = () => ({
  type: SubscriptionTypes.REMOVE_CONTRACT
})

export const updateFormStep = formStep => ({
  type: SubscriptionTypes.UPDATE_FORM_STEP,
  payload: formStep
})

export const toggleOtpModalVisibility = () => ({
  type: SubscriptionTypes.TOGGLE_OTP_MODAL_VISIBILITY
})

export const toggleUtilityModalVisibility = () => ({
  type: SubscriptionTypes.TOGGLE_UTILITY_MODAL_VISIBILITY
})

export const togglePodGreenEnergyResumeBox = () => ({
  type: SubscriptionTypes.TOGGLE_POD_GREEN_ENERGY_RESUME_BOX
})

export const togglePdrGreenEnergyResumeBox = () => ({
  type: SubscriptionTypes.TOGGLE_PDR_GREEN_ENERGY_RESUME_BOX
})

export const userRefreshTokenStart = () => ({
  type: SubscriptionTypes.REFRESH_USER_TOKEN_START
})

export const userRefreshTokenSuccess = data => ({
  type: SubscriptionTypes.REFRESH_USER_TOKEN_SUCCESS,
  payload: data
})

export const userRefreshTokenFailure = error => ({
  type: SubscriptionTypes.REFRESH_USER_TOKEN_FAILURE,
  payload: error
})

export const fetchContractStart = data => ({
  type: SubscriptionTypes.FETCH_CONTRACT_START,
  payload: data
})

export const fetchContractSuccess = data => ({
  type: SubscriptionTypes.FETCH_CONTRACT_SUCCESS,
  payload: data
})

export const fetchContractFailure = error => ({
  type: SubscriptionTypes.FETCH_CONTRACT_FAILURE,
  payload: error
})

export const fetchContactDefaultValuesStart = data => ({
  type: SubscriptionTypes.FETCH_CONTACT_DEFAULT_VALUES_START,
  payload: data
})

export const fetchContactDefaultValuesSuccess = data => ({
  type: SubscriptionTypes.FETCH_CONTACT_DEFAULT_VALUES_SUCCESS,
  payload: data
})

export const fetchContactDefaultValuesFailure = error => ({
  type: SubscriptionTypes.FETCH_CONTACT_DEFAULT_VALUES_FAILURE,
  payload: error
})

export const fetchChangeSupplierDefaultValuesStart = data => ({
  type: SubscriptionTypes.FETCH_CHANGE_SUPPLIER_DEFAULT_VALUES_START,
  payload: data
})

export const fetchChangeSupplierDefaultValuesSuccess = data => ({
  type: SubscriptionTypes.FETCH_CHANGE_SUPPLIER_DEFAULT_VALUES_SUCCESS,
  payload: data
})

export const fetchChangeSupplierDefaultValuesFailure = error => ({
  type: SubscriptionTypes.FETCH_CHANGE_SUPPLIER_DEFAULT_VALUES_FAILURE,
  payload: error
})

export const fetchBankAccountDefaultValuesStart = data => ({
  type: SubscriptionTypes.FETCH_BANK_ACCOUNT_DEFAULT_VALUES_START,
  payload: data
})

export const fetchBankAccountDefaultValuesSuccess = data => ({
  type: SubscriptionTypes.FETCH_BANK_ACCOUNT_DEFAULT_VALUES_SUCCESS,
  payload: data
})

export const fetchBankAccountDefaultValuesFailure = error => ({
  type: SubscriptionTypes.FETCH_BANK_ACCOUNT_DEFAULT_VALUES_FAILURE,
  payload: error
})

export const fetchCadastralDefaultValuesStart = data => ({
  type: SubscriptionTypes.FETCH_CADASTRAL_DEFAULT_VALUES_START,
  payload: data
})

export const fetchCadastralDefaultValuesSuccess = data => ({
  type: SubscriptionTypes.FETCH_CADASTRAL_DEFAULT_VALUES_SUCCESS,
  payload: data
})

export const fetchCadastralDefaultValuesFailure = error => ({
  type: SubscriptionTypes.FETCH_CADASTRAL_DEFAULT_VALUES_FAILURE,
  payload: error
})

export const fetchSupplyPointsDefaultValuesStart = data => ({
  type: SubscriptionTypes.FETCH_SUPPLY_POINTS_DEFAULT_VALUES_START,
  payload: data
})

export const fetchSupplyPointsDefaultValuesSuccess = data => ({
  type: SubscriptionTypes.FETCH_SUPPLY_POINTS_DEFAULT_VALUES_SUCCESS,
  payload: data
})

export const fetchSupplyPointsDefaultValuesFailure = error => ({
  type: SubscriptionTypes.FETCH_SUPPLY_POINTS_DEFAULT_VALUES_FAILURE,
  payload: error
})

export const submitContactFormStart = data => ({
  type: SubscriptionTypes.SUBMIT_CONTACT_FORM_START,
  payload: data
})

export const submitContactFormSuccess = data => ({
  type: SubscriptionTypes.SUBMIT_CONTACT_FORM_SUCCESS,
  payload: data
})

export const submitContactFormError = error => ({
  type: SubscriptionTypes.SUBMIT_CONTACT_FORM_FAILURE,
  payload: error
})

export const submitProposalFormStart = data => ({
  type: SubscriptionTypes.SUBMIT_PROPOSAL_FORM_START,
  payload: data
})

export const submitProposalFormSuccess = data => ({
  type: SubscriptionTypes.SUBMIT_PROPOSAL_FORM_SUCCESS,
  payload: data
})

export const submitProposalFormFailure = error => ({
  type: SubscriptionTypes.SUBMIT_PROPOSAL_FORM_FAILURE,
  payload: error
})

export const submitPaymentFormStart = data => ({
  type: SubscriptionTypes.SUBMIT_PAYMENT_FORM_START,
  payload: data
})

export const submitPaymentFormSuccess = data => ({
  type: SubscriptionTypes.SUBMIT_PAYMENT_FORM_SUCCESS,
  payload: data
})

export const submitPaymentFormFailure = error => ({
  type: SubscriptionTypes.SUBMIT_PAYMENT_FORM_FAILURE,
  payload: error
})

export const submitCadastralFormStart = data => ({
  type: SubscriptionTypes.SUBMIT_CADASTRAL_FORM_START,
  payload: data
})

export const submitCadastralFormSuccess = data => ({
  type: SubscriptionTypes.SUBMIT_CADASTRAL_FORM_SUCCESS,
  payload: data
})

export const submitCadastralFormFailure = error => ({
  type: SubscriptionTypes.SUBMIT_CADASTRAL_FORM_FAILURE,
  payload: error
})

export const submitSupplyPointsFormStart = data => ({
  type: SubscriptionTypes.SUBMIT_SUPPLY_POINTS_FORM_START,
  payload: data
})

export const submitSupplyPointsFormSuccess = () => ({
  type: SubscriptionTypes.SUBMIT_SUPPLY_POINTS_FORM_SUCCESS
})

export const submitSupplyPointsFormFailure = error => ({
  type: SubscriptionTypes.SUBMIT_SUPPLY_POINTS_FORM_FAILURE,
  payload: error
})

export const submitPodFormSuccess = data => ({
  type: SubscriptionTypes.SUBMIT_POD_FORM_SUCCESS,
  payload: data
})

export const submitPdrFormSuccess = data => ({
  type: SubscriptionTypes.SUBMIT_PDR_FORM_SUCCESS,
  payload: data
})

export const deletePodSuccess = () => ({
  type: SubscriptionTypes.DELETE_POD_SUCCESS
})

export const deletePdrSuccess = () => ({
  type: SubscriptionTypes.DELETE_PDR_SUCCESS
})

export const fetchContractReviewDataStart = data => ({
  type: SubscriptionTypes.FETCH_CONTRACT_REVIEW_DATA_START
})

export const fetchContractReviewDataSuccess = data => ({
  type: SubscriptionTypes.FETCH_CONTRACT_REVIEW_DATA_SUCCESS,
  payload: data
})

export const fetchContractReviewDataFailure = error => ({
  type: SubscriptionTypes.FETCH_CONTRACT_REVIEW_DATA_FAILURE,
  payload: error
})

export const fetchOtpSignersDataStart = () => ({
  type: SubscriptionTypes.FETCH_OTP_SIGNERS_DATA_START
})

export const fetchOtpSignersDataSuccess = data => ({
  type: SubscriptionTypes.FETCH_OTP_SIGNERS_DATA_SUCCESS,
  payload: data
})

export const fetchOtpSignersDataFailure = error => ({
  type: SubscriptionTypes.FETCH_OTP_SIGNERS_DATA_FAILURE,
  payload: error
})

export const fetchOtpCodeStart = data => ({
  type: SubscriptionTypes.FETCH_OTP_CODE_START,
  payload: data
})

export const fetchOtpCodeSuccess = () => ({
  type: SubscriptionTypes.FETCH_OTP_CODE_SUCCESS
})

export const fetchOtpCodeFailure = error => ({
  type: SubscriptionTypes.FETCH_OTP_CODE_FAILURE,
  payload: error
})

export const submitOtpSignatureStart = data => ({
  type: SubscriptionTypes.SUBMIT_OTP_SIGNATURE_START,
  payload: data
})

export const submitOtpSignatureSuccess = () => ({
  type: SubscriptionTypes.SUBMIT_OTP_SIGNATURE_SUCCESS
})

export const submitOtpSignatureFailure = error => ({
  type: SubscriptionTypes.SUBMIT_OTP_SIGNATURE_FAILURE,
  payload: error
})

export const toggleOtpSignatureModal = () => ({
  type: SubscriptionTypes.TOGGLE_OTP_SIGNATURE_MODAL
})

export const toggleTooltip = () => ({
  type: SubscriptionTypes.TOGGLE_TOOLTIP
})

export const saveOngoingLastStepValues = data => ({
  type: SubscriptionTypes.SAVE_ONGOING_LAST_STEP_VALUES,
  payload: data
})