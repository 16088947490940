//Set element fixed position on window scroll
export const fixedSideElementOnScroll = (containerElement, element, padding = 0) => {
  const containerElementOffsetTop = containerElement.offsetTop
  const elementPosition = getOffsetLeft(element)
  const elementWidth = element.offsetWidth

  if (window.pageYOffset > (containerElementOffsetTop - padding)) {
    element.setAttribute('style', `width: ${elementWidth}px; left: ${elementPosition}px`)
    element.classList.add("fixed")
  } else {
    element.removeAttribute('style')
    element.classList.remove("fixed")
  }
}

//Handle animation classes
export const animateCSS = (element, animationName, callback) => {
    const node = document.querySelector(element)
    node.classList.add('animated', animationName)

    function handleAnimationEnd() {
        node.classList.remove('animated', animationName)
        node.removeEventListener('animationend', handleAnimationEnd)

        if (typeof callback === 'function') callback()
    }

    node.addEventListener('animationend', handleAnimationEnd)
}

//Move side element if scrolling on footer section
export const moveSideFromFooter = () => {
  const footerElement = document.querySelector('footer')
  const side = document.querySelector('.contract-resume')
  if(side) {
    if ((window.innerHeight + window.scrollY) >= (document.body.offsetHeight - (footerElement.offsetHeight / 2))) {
      // you're at the bottom of the page
      side.classList.add('move-out')
    }
    else {
      side.classList.remove('move-out')
    }
  }
}

//Get element left offset relative to the document element
const getOffsetLeft = (elem) => {
  let offsetLeft = 0
  do {
    if(!isNaN(elem.offsetLeft)) {
      offsetLeft += elem.offsetLeft
    }
  } // eslint-disable-next-line
  while( elem = elem.offsetParent )

  return offsetLeft
}
