import React from 'react'
import { Button } from 'reactstrap'

import { ReactComponent as Logo } from '../../assets/svg/logo-ab.svg'

const ThankYou = () => (
  <div className="thank-you animated fadeIn">
    <section className="message">
      <div className="thank-you-content">
        <h1 className="title animated fadeInUp">
          Grazie per aver scelto
        </h1>
        <Logo className="logo animated fadeInUp" />
        <p className="animated fadeIn">A breve riceverai una copia del contratto firmato via email e un regalo per ringraziarti della fiducia!</p>
        <Button href="https://www.abenergie.it" className="ab-button animated fadeIn">Torna ad abenergie.it</Button>
      </div>
    </section>
  </div>
)

export default ThankYou
