import SubscriptionTypes from './subscription.types'
import SubscriptionStepsMap from '../../pages/SubscriptionPage/subscriptionSteps'
import {
  setContractOffersForUpdate, addContract, removeContract, addContractGreenEnergy,
  getDefaultFormStep, setInitialGreenEnergy, updateSignersData, updateResumeBox
} from './subscription.utils'

const INITIAL_STATE = {
  accessToken: null,
  firebaseUser: null,
  formStep: SubscriptionStepsMap.CONTACT_STEP,
  allowedStep: SubscriptionStepsMap.CONTACT_STEP,
  formFieldsValues: null,
  contractInfo: null,
  defaultEntitiesId: {
    contactId: null,
    contractId: null,
    bankAccountDetailId: null,
    cadastralDataId: null,
    podId: null,
    pdrId: null
  },
  ongoingLastStepValues: null,
  defaultValues: {
    changeSupplier: null,
    bankAccountDetail: null,
    cadastralData: null,
    supplyPoints: null
  },
  reviewData: null,
  contractPdf: null,
  signersData: [],
  utilityModal: {
    visibility: false,
    modalTitle: '',
    modalDescription: '',
    modalIcon: ''
  },
  otpSignatureModalVisibility: false,
  isSubmitting: false,
  isLoading: false,
  tooltipVisible: null,
  error: null
}

const subscriptionReducer = (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case SubscriptionTypes.SUBMIT_OTP_SIGNATURE_START:
    case SubscriptionTypes.SUBMIT_SUPPLY_POINTS_FORM_START:
    case SubscriptionTypes.SUBMIT_CADASTRAL_FORM_START:
    case SubscriptionTypes.SUBMIT_PAYMENT_FORM_START:
    case SubscriptionTypes.SUBMIT_PROPOSAL_FORM_START:
    case SubscriptionTypes.SUBMIT_CONTACT_FORM_START:
      return {
        ...state,
        isSubmitting: true,
        signersData: updateSignersData(action.payload, state)
      }

    case SubscriptionTypes.REFRESH_USER_TOKEN_SUCCESS:
      return {
        ...state,
        accessToken: action.payload.newToken
      }

    case SubscriptionTypes.FETCH_OTP_CODE_START:
      return {
        ...state,
        isSubmitting: true,
        signersData: updateSignersData(action.payload, state)
      }

    case SubscriptionTypes.SUBMIT_CONTACT_FORM_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        formStep: SubscriptionStepsMap.PROPOSAL_STEP,
        allowedStep: state.defaultEntitiesId.contactId ? state.allowedStep : SubscriptionStepsMap.PROPOSAL_STEP,
        defaultEntitiesId: {
          ...state.defaultEntitiesId,
          contactId: action.payload
        },
        error: null
      }

    case SubscriptionTypes.SUBMIT_PROPOSAL_FORM_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        ongoingLastStepValues: state.allowedStep === SubscriptionStepsMap.PROPOSAL_STEP ? null : state.ongoingLastStepValues,
        formStep: SubscriptionStepsMap.PAYMENT_STEP,
        allowedStep: state.defaultEntitiesId.contractId ? state.allowedStep : SubscriptionStepsMap.PAYMENT_STEP,
        defaultEntitiesId: {
          ...state.defaultEntitiesId,
          contractId: action.payload
        },
        error: null
      }

    case SubscriptionTypes.SUBMIT_PAYMENT_FORM_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        ongoingLastStepValues: state.allowedStep === SubscriptionStepsMap.PAYMENT_STEP ? null : state.ongoingLastStepValues,
        formStep: SubscriptionStepsMap.CADASTRAL_STEP,
        allowedStep: state.defaultEntitiesId.bankAccountDetailId ? state.allowedStep : SubscriptionStepsMap.CADASTRAL_STEP,
        defaultEntitiesId: {
          ...state.defaultEntitiesId,
          bankAccountDetailId: action.payload
        },
        error: null
      }

    case SubscriptionTypes.SUBMIT_CADASTRAL_FORM_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        ongoingLastStepValues: state.allowedStep === SubscriptionStepsMap.CADASTRAL_STEP ? null : state.ongoingLastStepValues,
        formStep: SubscriptionStepsMap.SUPPLY_POINTS_STEP,
        allowedStep: state.defaultEntitiesId.cadastralDataId ? state.allowedStep : SubscriptionStepsMap.SUPPLY_POINTS_STEP,
        defaultEntitiesId: {
          ...state.defaultEntitiesId,
          cadastralDataId: action.payload
        },
        error: null
      }

    case SubscriptionTypes.SUBMIT_SUPPLY_POINTS_FORM_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        ongoingLastStepValues: state.allowedStep === SubscriptionStepsMap.SUPPLY_POINTS_STEP ? null : state.ongoingLastStepValues,
        formStep: SubscriptionStepsMap.REVIEW_STEP,
        allowedStep: SubscriptionStepsMap.OTP_STEP,
        error: null
      }

    case SubscriptionTypes.SUBMIT_POD_FORM_SUCCESS:
      return {
        ...state,
        defaultEntitiesId: {
          ...state.defaultEntitiesId,
          podId: action.payload
        }
      }

    case SubscriptionTypes.SUBMIT_PDR_FORM_SUCCESS:
      return {
        ...state,
        defaultEntitiesId: {
          ...state.defaultEntitiesId,
          pdrId: action.payload
        }
      }

    case SubscriptionTypes.DELETE_POD_SUCCESS:
      return {
        ...state,
        defaultEntitiesId: {
          ...state.defaultEntitiesId,
          podId: null
        }
      }

    case SubscriptionTypes.DELETE_PDR_SUCCESS:
      return {
        ...state,
        defaultEntitiesId: {
          ...state.defaultEntitiesId,
          pdrId: null
        }
      }

    case SubscriptionTypes.FETCH_OTP_CODE_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        otpSignatureModalVisibility: true
      }

    case SubscriptionTypes.SUBMIT_OTP_SIGNATURE_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        otpSignatureModalVisibility: false,
        error: null
      }

    case SubscriptionTypes.SUBMIT_OTP_SIGNATURE_FAILURE:
    case SubscriptionTypes.FETCH_OTP_CODE_FAILURE:
    case SubscriptionTypes.SUBMIT_SUPPLY_POINTS_FORM_FAILURE:
    case SubscriptionTypes.SUBMIT_CADASTRAL_FORM_FAILURE:
    case SubscriptionTypes.SUBMIT_PAYMENT_FORM_FAILURE:
    case SubscriptionTypes.SUBMIT_PROPOSAL_FORM_FAILURE:
    case SubscriptionTypes.SUBMIT_CONTACT_FORM_FAILURE:
    case SubscriptionTypes.REFRESH_USER_TOKEN_FAILURE:
      return {
        ...state,
        isSubmitting: false,
        error: action.payload,
        utilityModal: {
          ...state.utilityModal,
          modalTitle: 'Si è verificato un problema!',
          modalDescription: action.payload.error?.message || action.payload.message || 'Ci dispiace ma qualcosa è andato storto...',
          modalIcon: ['fas', 'exclamation-triangle'],
          isVisible: true
        }
      }

    case SubscriptionTypes.FETCH_OTP_SIGNERS_DATA_START:
    case SubscriptionTypes.FETCH_CONTRACT_REVIEW_DATA_START:
    case SubscriptionTypes.FETCH_CONTRACT_START:
      return {
        ...state,
        isLoading: true,
        error: null
      }

    case SubscriptionTypes.FETCH_CONTRACT_SUCCESS:
      return {
        ...state,
        firebaseUser: action.payload.firebaseUser,
        abUser: action.payload.abUser,
        accessToken: action.payload.accessToken,
        contractInfo: {
          ...action.payload.contractInfo,
          offers: setContractOffersForUpdate(action.payload),
          podGreenEnergy: setInitialGreenEnergy('E', action.payload),
          pdrGreenEnergy: setInitialGreenEnergy('G', action.payload)
        },
        formFieldsValues: action.payload.formFieldsValues,
        defaultEntitiesId: {
          ...state.defaultEntitiesId,
          ...action.payload.defaultEntitiesId
        },
        formStep: getDefaultFormStep(action.payload.defaultEntitiesId),
        allowedStep: getDefaultFormStep(action.payload.defaultEntitiesId),
        tooltipVisible: action.payload.defaultEntitiesId.contactId !== undefined ? false : null,
        isLoading: false
      }

    case SubscriptionTypes.FETCH_CONTRACT_REVIEW_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        reviewData: action.payload,
        defaultValues: {
          changeSupplier: null,
          bankAccountDetail: null,
          cadastralData: null,
          supplyPoints: null
        },
        error: null
      }

    case SubscriptionTypes.FETCH_OTP_SIGNERS_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        signersData: action.payload.signers,
        contractPdf: action.payload.signedContractLink
      }

    case SubscriptionTypes.FETCH_CONTRACT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        utilityModal: {
          ...state.utilityModal,
          modalTitle: 'Si è verificato un problema!',
          modalDescription: action.payload.error?.message,
          modalIcon: ['fas', 'exclamation-triangle'],
          isVisible: true
        }
      }

    case SubscriptionTypes.FETCH_OTP_SIGNERS_DATA_FAILURE:
    case SubscriptionTypes.FETCH_CONTRACT_REVIEW_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        utilityModal: {
          ...state.utilityModal,
          modalTitle: 'Si è verificato un problema!',
          modalDescription: action.payload.error?.message,
          modalIcon: ['fas', 'exclamation-triangle'],
          isVisible: true
        }
      }

    case SubscriptionTypes.FETCH_CONTACT_DEFAULT_VALUES_START:
    case SubscriptionTypes.FETCH_CHANGE_SUPPLIER_DEFAULT_VALUES_START:
    case SubscriptionTypes.FETCH_BANK_ACCOUNT_DEFAULT_VALUES_START:
    case SubscriptionTypes.FETCH_CADASTRAL_DEFAULT_VALUES_START:
    case SubscriptionTypes.FETCH_SUPPLY_POINTS_DEFAULT_VALUES_START:
      return {
        ...state,
        isLoading: true
      }

    case SubscriptionTypes.FETCH_CONTACT_DEFAULT_VALUES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        defaultValues: {
          contact: action.payload,
          changeSupplier: null,
          bankAccountDetail: null,
          cadastralData: null,
          supplyPoints: null
        }
      }

    case SubscriptionTypes.FETCH_CHANGE_SUPPLIER_DEFAULT_VALUES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        defaultValues: {
          changeSupplier: action.payload,
          contact: null,
          bankAccountDetail: null,
          cadastralData: null,
          supplyPoints: null
        }
      }

    case SubscriptionTypes.FETCH_BANK_ACCOUNT_DEFAULT_VALUES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        defaultValues: {
          bankAccountDetail: action.payload,
          contact: null,
          changeSupplier: null,
          cadastralData: null,
          supplyPoints: null
        }
      }

    case SubscriptionTypes.FETCH_CADASTRAL_DEFAULT_VALUES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        defaultValues: {
          cadastralData: action.payload,
          contact: null,
          changeSupplier: null,
          bankAccountDetail: null,
          supplyPoints: null
        }
      }

    case SubscriptionTypes.FETCH_SUPPLY_POINTS_DEFAULT_VALUES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        contractInfo: {
          ...state.contractInfo,
          podGreenEnergy: action.payload.podGreenEnergy ? action.payload.podGreenEnergy : state.contractInfo.podGreenEnergy,
          pdrGreenEnergy: action.payload.pdrGreenEnergy ? action.payload.pdrGreenEnergy : state.contractInfo.pdrGreenEnergy
        },
        defaultValues: {
          supplyPoints: action.payload,
          contact: null,
          changeSupplier: null,
          bankAccountDetail: null,
          cadastralData: null
        }
      }

    case SubscriptionTypes.FETCH_CONTACT_DEFAULT_VALUES_FAILURE:
    case SubscriptionTypes.FETCH_CHANGE_SUPPLIER_DEFAULT_VALUES_FAILURE:
    case SubscriptionTypes.FETCH_BANK_ACCOUNT_DEFAULT_VALUES_FAILURE:
    case SubscriptionTypes.FETCH_CADASTRAL_DEFAULT_VALUES_FAILURE:
    case SubscriptionTypes.FETCH_SUPPLY_POINTS_DEFAULT_VALUES_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        utilityModal: {
          ...state.utilityModal,
          modalTitle: 'Si è verificato un problema!',
          modalDescription: action.payload.error.message,
          modalIcon: ['fas', 'exclamation-triangle'],
          isVisible: true
        }
      }

    case SubscriptionTypes.ADD_CONTRACT:
      return {
        ...state,
        contractInfo: {
          ...state.contractInfo,
          offers: addContract(state),
          podGreenEnergy: addContractGreenEnergy('E', state),
          pdrGreenEnergy: addContractGreenEnergy('G', state)
        }
      }

    case SubscriptionTypes.REMOVE_CONTRACT:
      return {
        ...state,
        contractInfo: {
          ...state.contractInfo,
          offers: removeContract(state)
        }
      }

    case SubscriptionTypes.UPDATE_FORM_STEP:
      return {
        ...state,
        formStep: action.payload,
        tooltipVisible: false,
        contractInfo: {
          ...state.contractInfo,
          offers: updateResumeBox(state)
        },
        defaultValues: {
          changeSupplier: null,
          bankAccountDetail: null,
          cadastralData: null,
          supplyPoints: null
        },
        error: null
      }

    case SubscriptionTypes.TOGGLE_POD_GREEN_ENERGY_RESUME_BOX:
      return {
        ...state,
        contractInfo: {
          ...state.contractInfo,
          podGreenEnergy: !state.contractInfo.podGreenEnergy
        }
      }

    case SubscriptionTypes.TOGGLE_PDR_GREEN_ENERGY_RESUME_BOX:
      return {
        ...state,
        contractInfo: {
          ...state.contractInfo,
          pdrGreenEnergy: !state.contractInfo.pdrGreenEnergy
        }
      }

    case SubscriptionTypes.TOGGLE_OTP_MODAL_VISIBILITY:
      return {
        ...state,
        otpSignatureModalVisibility: !state.otpSignatureModalVisibility
      }

    case SubscriptionTypes.TOGGLE_UTILITY_MODAL_VISIBILITY:
      return {
        ...state,
        utilityModal: {
          ...state.utilityModal,
          isVisible: !state.utilityModal.isVisible
        }
      }

    case SubscriptionTypes.SET_UTILITY_MODAL_CONTENT:
      return {
        ...state,
        utilityModal: {
          ...action.payload
        }
      }

    case SubscriptionTypes.TOGGLE_OTP_SIGNATURE_MODAL:
      return {
        ...state,
        otpSignatureModalVisibility: !state.otpSignatureModalVisibility
      }

    case SubscriptionTypes.TOGGLE_TOOLTIP:
      return {
        ...state,
        tooltipVisible: state.tooltipVisible === null ? false : !state.tooltipVisible
      }

    case SubscriptionTypes.SAVE_ONGOING_LAST_STEP_VALUES:
      return {
        ...state,
        ongoingLastStepValues: action.payload
      }

    default:
      return state
  }
}

export default subscriptionReducer
