import React, { useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const PowerValueModal = () => {
  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)

  return (
    <>
      <Button className="info-button" onClick={toggle}>
        <FontAwesomeIcon icon={['fas', 'question-circle']} className="icon" />
      </Button>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Cosa è la potenza disponibile?</ModalHeader>
        <ModalBody>
          La potenza disponibile è la potenza massima prelevabile e viene espressa in kW.
        </ModalBody>
      </Modal>
    </>
  )
}

export default PowerValueModal
