import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { Button } from 'reactstrap'

import SubscriptionStepsMap from '../../pages/SubscriptionPage/subscriptionSteps'
import { updateFormStep, toggleTooltip } from '../../redux/subscription/subscription.actions'
import { selectFormStep, selectAllowedStep, selectShowTooltip } from '../../redux/subscription/subscription.selectors'

const TimelineNavigationButtons = ({ formStep, allowedStep, handleFormStep, toggleTooltip }) => (
  <div className="timeline-navigation-buttons" id="timeline-navigation-buttons">
    <div>
      {formStep > 1 &&
        <Button
          color="link"
          className="step-back"
          onClick={() => handleFormStep(formStep - 1)}
        >
          Indietro
        </Button>
      }
    </div>
    <div>
      {formStep + 1 <= allowedStep && formStep + 1 < SubscriptionStepsMap.OTP_STEP &&
        <Button
          color="link"
          className="step-forward"
          onClick={() => handleFormStep(formStep + 1)}
        >
          Avanti
        </Button>
      }
    </div>

  </div>
)

const mapStateToProps = createStructuredSelector({
  formStep: selectFormStep,
  allowedStep: selectAllowedStep,
  showTooltip: selectShowTooltip
})

const mapDispatchToProps = dispatch => ({
  handleFormStep: formStep => dispatch(updateFormStep(formStep)),
  toggleTooltip: () => dispatch(toggleTooltip())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TimelineNavigationButtons)
