import React, { useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import RequestOtpCodeForm from '../RequestOtpCodeForm/RequestOtpCodeForm.component'
import OtpSignatureModal from '../OtpSignatureModal/OtpSignatureModal.component'

const OtpSigners = ({ currentSigner, alreadySignedData }) => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [])

  return (
    <>
      <section className="resume-section">
        {currentSigner &&
          <>
            <div className="current-signer">
              <div className="resume-header">
                <h3 className="form-title">
                  {currentSigner.name}, richiedi il codice di attivazione per firmare il tuo contratto
                </h3>
              </div>
              <div className="resume-data form-container">
                <RequestOtpCodeForm signer={currentSigner} />
              </div>
            </div>
            <OtpSignatureModal currentSigner={currentSigner} />
          </>
        }
        <div className="already-signed-container">
          {alreadySignedData.map((signer, i) => (
            <div className="resume-header signed" key={i}>
              <h3 className="form-title">
                <FontAwesomeIcon icon={['fas', 'check']} className="icon mr-2" />{' '}
                {signer.name} {signer.surname} ha firmato
              </h3>
            </div>
          ))}
        </div>
      </section>
    </>
  )
}

export default OtpSigners
