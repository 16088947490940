//!IMPORTANT This component presumes you're using react-final-form
import React, { useEffect, useRef } from 'react'
import { Field } from 'react-final-form'
import { FormGroup, Input, FormFeedback, Label } from 'reactstrap'
import classnames from 'classnames'

const CustomFormField = ({ children, focusOnMount, ...props }) => {
  const inputRef = useRef(null)
  useEffect(() => {
    if(focusOnMount) inputRef.current.focus()
  }, [focusOnMount, inputRef])

  return (
    <FormGroup className={classnames({'m-0': props.type === 'hidden'})}>
      <Field {...props}>
        {({ placeholder, forcedPlaceholder, input, meta: { error, touched, dirty }, ...otherProps }) => (
          <>
            <Input placeholder={forcedPlaceholder || null} innerRef={inputRef} invalid={error && touched ? true : false} {...input} {...otherProps} />
            {placeholder &&
              <Label for={input.name} className={`${input.value.length ? 'shrink' : ''} form-input-label`}>{placeholder}</Label>
            }
            <FormFeedback>{error}</FormFeedback>
          </>
        )}
      </Field>
      {children}
    </FormGroup>
  )
}

export default CustomFormField
