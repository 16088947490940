import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { ListGroup, Button } from 'reactstrap'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import SubscriptionStepsMap from '../../pages/SubscriptionPage/subscriptionSteps'
import ReviewData from '../ReviewData/ReviewData.component'
import { selectReviewData } from '../../redux/subscription/subscription.selectors'
import { updateFormStep } from '../../redux/subscription/subscription.actions'

const BusinessReview = ({
  reviewData: { proposalData, paymentData, cadastralData, podData, pdrData }, updateFormStep
}) => (
  <div className="resume">
    <section className="resume-section">
      <div className="resume-header">
        <h3 className="form-title">
          Dati contatto
        </h3>
        <Button
          color="link"
          onClick={() => updateFormStep(SubscriptionStepsMap.CONTACT_STEP)}
        >
          <FontAwesomeIcon icon={['fas', 'pen']} className="icon" />
        </Button>
      </div>
      <div className="resume-data">
        <ListGroup>
          <ReviewData label="Ragione sociale" value={proposalData.customerBusinessName} />
          <ReviewData label="Telefono" value={proposalData.contactMobileNumber} />
          <ReviewData label="Email" value={proposalData.contactEmail} />
        </ListGroup>
      </div>
    </section>

    <section className="resume-section">
      <div className="resume-header">
        <h3 className="form-title">
          Dati titolare
        </h3>
        <Button
          color="link"
          onClick={() => updateFormStep(SubscriptionStepsMap.PROPOSAL_STEP)}
        >
          <FontAwesomeIcon icon={['fas', 'pen']} className="icon" />
        </Button>
      </div>
      <div className="resume-data">
        <ListGroup>
          <ReviewData label="Toponimo" value={proposalData.customerToponymId?.label || ''} />
          <ReviewData label="Indirizzo" value={proposalData.customerAddress} />
          <ReviewData label="N° civico" value={proposalData.customerStreetNumber} />
          <ReviewData label="Comune" value={`${proposalData.customerTownName.town} (${proposalData.customerTownName.province})`} />
          <ReviewData label="CAP" value={proposalData.customerPostalCode?.value} />
          <ReviewData label="Codice fiscale" value={proposalData.customerFiscalCode} />
          <ReviewData label="P.IVA" value={proposalData.customerVatNumber} />
          <ReviewData label="Email PEC" value={proposalData.contactPec} />
        </ListGroup>
      </div>
      <div className="resume-data">
        <ListGroup>
          <ReviewData label="Nome" value={proposalData.identityName} />
          <ReviewData label="Cognome" value={proposalData.identitySurname} />
          <ReviewData label="Sesso" value={proposalData.customerSex?.label || ''} />
          <ReviewData label="Codice fiscale" value={proposalData.identityFiscalCode} />
          <ReviewData label="Tipo documento d'identità" value={proposalData.identityDocumentType?.label || ''} />
          <ReviewData label="Numero documento" value={proposalData.identityDocumentNumber} />
          <ReviewData label="Rilasciato il" value={moment(proposalData.identityDocumentReleaseDate).format('DD/MM/YYYY')} />
          <ReviewData label="Rilasciato da" value={proposalData.identityDocumentEntity?.label || ''} />
          <ReviewData label="Trattamento dati" value={proposalData.marketingIndirectCommunication === true ? 'Sì' : 'No'} />
        </ListGroup>
      </div>
    </section>

    <section className="resume-section">
      <div className="resume-header">
        <h3 className="form-title">
          Dati pagamento
        </h3>
        <Button
          color="link"
          onClick={() => updateFormStep(SubscriptionStepsMap.PAYMENT_STEP)}
        >
          <FontAwesomeIcon icon={['fas', 'pen']} className="icon" />
        </Button>
      </div>
      <div className="resume-data">
        <ListGroup>
          <ReviewData label="IBAN" value={paymentData.iban} />
          <ReviewData label="Nome titolare" value={`${paymentData.name} ${paymentData.surname}`} />
          <ReviewData label="Codice fiscale" value={paymentData.fiscalCode} />
        </ListGroup>
      </div>
    </section>

    <section className="resume-section">
      <div className="resume-header">
        <h3 className="form-title">
          Dati recapito
        </h3>
        <Button
          color="link"
          onClick={() => updateFormStep(SubscriptionStepsMap.CADASTRAL_STEP)}
        >
          <FontAwesomeIcon icon={['fas', 'pen']} className="icon" />
        </Button>
      </div>
      <div className="resume-data">
        <ListGroup>
          <ReviewData label="Toponimo" value={cadastralData.toponymId?.label || ''} />
          <ReviewData label="Indirizzo" value={cadastralData.streetName} />
          <ReviewData label="N° civico" value={cadastralData.streetNo} />
          <ReviewData label="Comune" value={`${cadastralData.townName.town} (${cadastralData.townName.province})`} />
          <ReviewData label="CAP" value={cadastralData.postalCode?.value} />
        </ListGroup>
      </div>
      {podData && podData.billingShippingAddress === 3 &&
        <div className="resume-data">
          <h5 className="sub-section-title">
            Indirizzo di consegna fattura cartacea
          </h5>
          <ListGroup>
            <ReviewData label="Presso" value={podData.atName} />
            <ReviewData label="Toponimo" value={podData.toponymId?.label || ''} />
            <ReviewData label="Indirizzo" value={podData.streetName} />
            <ReviewData label="N° civico" value={podData.streetNo} />
            <ReviewData label="Comune" value={`${podData.townName.town} (${podData.townName.province})`} />
            <ReviewData label="CAP" value={podData.postalCode?.value} />
          </ListGroup>
        </div>
      }
      {pdrData && pdrData.billingShippingAddress === 3 && !podData &&
        <div className="resume-data">
          <h5 className="sub-section-title">
            Indirizzo di consegna fattura cartacea
          </h5>
          <ListGroup>
            <ReviewData label="Presso" value={pdrData.atName} />
            <ReviewData label="Toponimo" value={pdrData.toponymId?.label || ''} />
            <ReviewData label="Indirizzo" value={pdrData.streetName} />
            <ReviewData label="N° civico" value={pdrData.streetNo} />
            <ReviewData label="Comune" value={`${pdrData.townName.town} (${pdrData.townName.province})`} />
            <ReviewData label="CAP" value={pdrData.postalCode?.value} />
          </ListGroup>
        </div>
      }
    </section>

    <section className="resume-section">
      <div className="resume-header">
        <h3 className="form-title">
          Dati fornitura
        </h3>
        <Button
          color="link"
          onClick={() => updateFormStep(SubscriptionStepsMap.SUPPLY_POINTS_STEP)}
        >
          <FontAwesomeIcon icon={['fas', 'pen']} className="icon" />
        </Button>
      </div>
      {podData &&
        <div className="resume-data">
          <ListGroup>
            <ReviewData label="Codice POD" value={podData.no} />
            <ReviewData label="Potenza disponibile" value={podData.powerValue} />
            <ReviewData label="Tensione di allacciamento" value={podData.connectionVoltageId?.label || ''} />
            <ReviewData label="Tipo d'uso" value={podData.electricUseTypeId?.label || ''} />
          </ListGroup>
        </div>
      }
      {pdrData &&
        <div className="resume-data">
          <ListGroup>
            <ReviewData label="Codice PDR" value={pdrData.no} />
            <ReviewData label="Consumo annuo" value={pdrData.annualConsumption} />
            <ReviewData label="Attuale fornitore" value={pdrData.vendorName.label} />
            <ReviewData label="Tensione di allacciamento" value={pdrData.gasUseCategoryCode?.label || ''} />
            <ReviewData label="Tipo d'uso" value={pdrData.gasUseTypeId?.label || ''} />
          </ListGroup>
        </div>
      }
    </section>

    <div className="form-buttons">
      <Button 
        className="step-back"
        color="link"
        onClick={() => updateFormStep(SubscriptionStepsMap.SUPPLY_POINTS_STEP)}
      >
        Indietro
      </Button>
      <Button
        className="ab-button"
        onClick={() => updateFormStep(SubscriptionStepsMap.OTP_STEP)}
      >
        Avanti
      </Button>
    </div>
  </div>
)

const mapStateToProps = createStructuredSelector({
  reviewData: selectReviewData
})

const mapDispatchToProps = dispatch => ({
  updateFormStep: nextStep => dispatch(updateFormStep(nextStep))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BusinessReview)
