import React, { useState } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { Form } from 'react-final-form'
import { Button, Label } from 'reactstrap'

import { required, emailCheck, euPhoneCheck } from '../validations'
import { selectIsSubmitting, selectContactDefaultValues } from '../../../redux/subscription/subscription.selectors'
import { submitContactFormStart } from '../../../redux/subscription/subscription.actions'
import CustomFormField from '../CustomFormField/CustomFormField.component'
import CustomCheckboxField from '../CustomCheckboxField/CustomCheckboxField.component'
import CustomRadioField from '../CustomRadioField/CustomRadioField.component'
import ButtonWithLoader from '../../ButtonWithLoader/ButtonWithLoader.component'
import InfoModal from '../../InfoModal/InfoModal.component'
import PrivacyText from '../../PrivacyText/PrivacyText.component'
import MarketingText from '../../MarketingText/MarketingText.component'
import GeneralConditionsModal from '../GeneralConditionsModal/GeneralConditionsModal.component'
import CodCivModal from '../CodCivModal/CodCivModal.component'

const DomesticContactForm = ({ isSubmitting, defaultValues, submitContactForm }) => {
  const onSubmit = (values) => submitContactForm({ values })

  const [ privacyModalVisibility, setPrivacyModalVisibility ] = useState(false)
  const togglePrivacyModal = () => setPrivacyModalVisibility(!privacyModalVisibility)

  const [ marketingModalVisibility, setMarketingModalVisibility ] = useState(false)
  const toggleMarketingModal = () => setMarketingModalVisibility(!marketingModalVisibility)

  const [ generalConditionsVisibility, setGeneralConditionsVisibility ] = useState(false)
  const toggleGeneralConditionsModal = () => setGeneralConditionsVisibility(!generalConditionsVisibility)
  const [ codCivVisibility, setCodCivVisibility ] = useState(false)
  const toggleCodCivModal = () => setCodCivVisibility(!codCivVisibility)

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        ...defaultValues,
        commercialCommunication: defaultValues?.commercialCommunication.toString() || null,
        marketingIndirectCommunication: defaultValues?.marketingIndirectCommunication.toString() || null
      }}
    >
      {({ handleSubmit, form, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit} className="login-form">
          <CustomFormField
            id="customerName"
            name="customerName"
            type="text"
            validate={required}
            placeholder="Nome"
            className="ab-input"
            maxLength="80"
            focusOnMount={true}
          />
          <CustomFormField
            id="customerSurname"
            name="customerSurname"
            type="text"
            validate={required}
            placeholder="Cognome"
            className="ab-input"
            maxLength="80"
          />
          <CustomFormField
            id="contactMobileNumber"
            name="contactMobileNumber"
            type="text"
            validate={euPhoneCheck}
            placeholder="Numero cellulare"
            className="ab-input"
            maxLength="20"
          />
          <CustomFormField
            id="contactEmail"
            name="contactEmail"
            type="text"
            validate={emailCheck}
            placeholder="Email"
            className="ab-input"
            maxLength="80"
          />

          <CustomCheckboxField
            id="generalConditions"
            name="generalConditions"
            validate={required}
          >
            Ho letto le <Button onClick={toggleGeneralConditionsModal} color="link" className="checkbox-btn">Condizioni Generali di Contratto</Button>
            <GeneralConditionsModal
              modalVisibility={generalConditionsVisibility}
              toggleModal={toggleGeneralConditionsModal}
              customerType="domestic"
            />
          </CustomCheckboxField>

          <div>
            <CustomCheckboxField
              id="civilCode"
              name="civilCode"
              validate={required}
            >
              Ho preso visione e accetto quanto ai sensi degli <Button onClick={toggleCodCivModal} color="link" className="checkbox-btn">articoli 1341 e 1342 Cod. Civ.</Button>
              <CodCivModal
                modalVisibility={codCivVisibility}
                toggleModal={toggleCodCivModal}
                customerType="domestic"
              />
            </CustomCheckboxField>
          </div>

          <div className="radio-group">
            <Label className="radio-label">
              Dichiaro di aver preso visione dell'
              <Button onClick={togglePrivacyModal} color="link" className="checkbox-btn">Informativa sulla privacy</Button>&nbsp;
              e presto il consenso al trattamento dei dati personali per l’invio di comunicazioni commerciali
              per finalità di marketing diretto
            </Label>
            <InfoModal
              id="marketing-modal"
              title="Nota informativa privacy"
              visibility={privacyModalVisibility}
              toggle={togglePrivacyModal}
            >
              <PrivacyText />
            </InfoModal>
            <div className="radio-options">
              <CustomRadioField
                value="true"
                name="commercialCommunication"
                description="Consento"
                validate={required}
              />
              <CustomRadioField
                value="false"
                name="commercialCommunication"
                description="Nego il consenso"
                noValidationMessage={true}
              />
            </div>
          </div>

          <div className="radio-group">
            <Label className="radio-label">
              Ho preso visione dell'
              <Button color="link" className="checkbox-btn" onClick={toggleMarketingModal} size="sm">
                informativa privacy
              </Button>{' '}
              e presto il consenso al trattamento dei dati personali per le finalità di marketing indiretto, indicate nel presente modulo d’ordine al punto 3 lett. f.
            </Label>
            <InfoModal
              id="marketing-modal"
              title="Nota informativa privacy"
              visibility={marketingModalVisibility}
              toggle={toggleMarketingModal}
            >
              <MarketingText />
            </InfoModal>
            <div className="radio-options">
              <CustomRadioField
                value="true"
                name="marketingIndirectCommunication"
                description="Consento"
                validate={required}
              />
              <CustomRadioField
                value="false"
                name="marketingIndirectCommunication"
                description="Nego il consenso"
                noValidationMessage={true}
              />
            </div>
          </div>

          <div className="form-buttons justify-content-end">
            <ButtonWithLoader
              type="submit"
              otherclasses="ab-button"
              buttonLabel="Avanti"
              onClick={handleSubmit}
              isFetching={isSubmitting}
              disabled={isSubmitting}
            />
          </div>
        </form>
      )}
    </Form>
  )
}

const mapStateToProps = createStructuredSelector({
  isSubmitting: selectIsSubmitting,
  defaultValues: selectContactDefaultValues
})

const mapDispatchToProps = dispatch => ({
  submitContactForm: data => dispatch(submitContactFormStart(data))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DomesticContactForm)
