import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { selectModalVisibility, selectModalTitle, selectModalDescription, selectModalIcon } from '../../redux/modal/modal.selectors'
import { toggleModalVisibility } from '../../redux/modal/modal.actions'

const UtilityModal = ({ isVisible, title, description, icon, toggle }) => {
  return(
    <Modal isOpen={isVisible} toggle={toggle} className="utility-modal">
      <ModalHeader toggle={toggle}>
        {icon &&
          <FontAwesomeIcon size="lg" icon={icon} className="mr-2" />
        }
        {title}
      </ModalHeader>
      <ModalBody>
        {description}
      </ModalBody>
    </Modal>
  )
}

const mapStateToProps = createStructuredSelector({
  isVisible: selectModalVisibility,
  title: selectModalTitle,
  description: selectModalDescription,
  icon: selectModalIcon
})

const mapDispatchToProps = dispatch => ({
  toggle: () => dispatch(toggleModalVisibility())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UtilityModal)
