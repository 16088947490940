import React, { useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const PodInfoModal = () => {
  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)

  return (
    <>
      <Button className="info-button" onClick={toggle}>
        <FontAwesomeIcon icon={['fas', 'question-circle']} className="icon" />
      </Button>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Cosa è il codice POD?</ModalHeader>
        <ModalBody>
          Il codice POD identifica l’esatto punto di prelievo dell’energia elettrica dalla rete nazionale,
          esso non cambia mai, rimane lo stesso anche se si cambia fornitore.<br />
          Si trova solitamente nel primo foglio della bolletta ed è composto da 14-15 caratteri alfanumerici.<br />
          Esempio di codice POD: IT 123 E 12345678
        </ModalBody>
      </Modal>
    </>
  )
}

export default PodInfoModal
