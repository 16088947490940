import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { Form, FormSpy } from 'react-final-form'
import { Button } from 'reactstrap'

import { required } from '../validations'
import { submitCadastralFormStart, updateFormStep, saveOngoingLastStepValues } from '../../../redux/subscription/subscription.actions'
import {
  selectIsSubmitting, selectFormFieldsValues, selectContractId,
  selectCadastralDataDefaultValues, selectAccessToken, selectOngoingLastStepValues,
  selectFormStep, selectAllowedStep
} from '../../../redux/subscription/subscription.selectors'
import WhenFieldChanges from '../WhenFieldChanges/WhenFieldChanges.component'
import CustomFormField from '../CustomFormField/CustomFormField.component'
import ExtendedSelectField from '../ExtendedSelectField/ExtendedSelectField.component'
import ExtendedAutocompleteFormField from '../ExtendedAutocompleteFormField/ExtendedAutocompleteFormField.component'
import ExtendedCustomZipCodeSelect from '../ExtendedCustomZipCodeSelect/ExtendedCustomZipCodeSelect.component'
import ButtonWithLoader from '../../ButtonWithLoader/ButtonWithLoader.component'
import { updateFieldValue } from './utils'
import { fetchTowns } from '../../../api'
import SubscriptionStepsMap from '../../../pages/SubscriptionPage/subscriptionSteps'

const CadastralForm = ({ 
  contractId, isSubmitting, formFieldsValues, submitCadastralForm, 
  defaultValues, accessToken, handleFormStep, saveFormValues, ongoingLastStepValues,
  formStep, allowedStep
}) => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [])

  const onSubmit = (values) => submitCadastralForm({ values })

  const formValues = defaultValues || ongoingLastStepValues

  return (
    <div className="form-container">
      <Form
        onSubmit={onSubmit}
        mutators={{updateFieldValue}}
        initialValues={{
          ...formValues,
          contractId: contractId
        }}
      >
        {({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit} className="login-form" autoComplete="off">
            <section className="form-section supply-address">
              <ExtendedSelectField
                id="toponymId"
                name="toponymId"
                options={formFieldsValues.supplyToponym}
                validate={required}
                fieldLabel="Tipo di indirizzo (via, piazza, etc)"
                placeholder="Tipo di indirizzo (via, piazza, etc)"
                className="extended-select"
                classNamePrefix="extended-select"
              />
              <CustomFormField
                id="streetName"
                name="streetName"
                maxLength="128"
                type="text"
                validate={required}
                placeholder="Indirizzo"
                className="ab-input"
              />
              <CustomFormField
                id="streetNo"
                name="streetNo"
                maxLength="4"
                type="text"
                validate={required}
                placeholder="N° civico"
                className="ab-input"
              />
              <ExtendedAutocompleteFormField
                id="townName"
                name="townName"
                fieldLabel="Comune"
                placeholder="Comune"
                fetchFunction={fetchTowns(accessToken)}
                validate={required}
                maxLength="80"
                minChars={2}
                className="extended-autocomplete"
                classNamePrefix="extended-autocomplete"
                getOptionLabel={option => `${option.town} (${option.province})`}
                getOptionValue={option => ({ town: option.town, province: option.province })}
                noOptionsMessage={() => 
                  <span className="autocomplete-suggestion">Indica le prime lettere del comune per trovarlo nella lista</span>
                }
              />
              <WhenFieldChanges
                field="townName"
                set="postalCode"
                to={null}
              />
              <ExtendedCustomZipCodeSelect
                id="postalCode"
                name="postalCode"
                className="extended-select"
                classNamePrefix="extended-select"
                fieldLabel="CAP"
                placeholder="CAP"
                fieldToListen="townName"
                fieldToListenDefaultValue={defaultValues?.townName || null}
                validate={required}
              />
            </section>

            <div className="form-buttons">
              <Button 
                className="step-back"
                color="link"
                onClick={() => handleFormStep(SubscriptionStepsMap.PAYMENT_STEP)}
              >
                Indietro
              </Button>
              <ButtonWithLoader
                type="submit"
                otherclasses="ab-button"
                buttonLabel="Avanti"
                onClick={handleSubmit}
                isFetching={isSubmitting}
                disabled={isSubmitting}
              />
            </div>
            <FormSpy 
              subscription={{ values: true }}
              onChange={({ values }) => {
                if(formStep === allowedStep) {
                  saveFormValues(values)
                }
              }}
            />
          </form>
        )}
      </Form>
    </div>
  )
}

const mapStateToProps = createStructuredSelector({
  isSubmitting: selectIsSubmitting,
  formFieldsValues: selectFormFieldsValues,
  contractId: selectContractId,
  defaultValues: selectCadastralDataDefaultValues,
  accessToken: selectAccessToken,
  ongoingLastStepValues: selectOngoingLastStepValues,
  formStep: selectFormStep,
  allowedStep: selectAllowedStep
})

const mapDispatchToProps = dispatch => ({
  submitCadastralForm: data => dispatch(submitCadastralFormStart(data)),
  handleFormStep: formStep => dispatch(updateFormStep(formStep)),
  saveFormValues: values => dispatch(saveOngoingLastStepValues(values))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CadastralForm)
