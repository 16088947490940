import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import { fetchOtpSignersDataStart } from '../../../redux/subscription/subscription.actions'
import OtpSignersContainer from './OtpSignersContainer.component'

const OtpStep = ({ fetchOtpSignersDataStart }) => {
  useEffect(() => {
    fetchOtpSignersDataStart()
  }, [fetchOtpSignersDataStart])

  return (
    <div className="resume">
      <OtpSignersContainer />
    </div>
  )
}

const mapDispatchToProps = dispatch => ({
  fetchOtpSignersDataStart: () => dispatch(fetchOtpSignersDataStart())
})

export default connect(
  null,
  mapDispatchToProps
)(OtpStep)
