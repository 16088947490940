import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const InfoModal = ({ visibility, toggle, title, icon, children, ...props }) => (
  <Modal isOpen={visibility} toggle={toggle} {...props}>
    <ModalHeader toggle={toggle}>
      {icon &&
        <FontAwesomeIcon size="lg" icon={icon} className="mr-2" />
      }
      {title}
    </ModalHeader>
    <ModalBody>
      {children}
    </ModalBody>
    <ModalFooter>
      <Button className="ab-button" onClick={toggle}>Chiudi</Button>
    </ModalFooter>
  </Modal>
)

export default InfoModal
