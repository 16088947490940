export const carouselItems = [
  {
    caption: 'Non avrai nessuna interruzione di fornitura',
    icon: 'no-interruzione.svg',
    key: '1'
  },
  {
    caption: 'Avrai un referente dedicato sempre a tua disposizione',
    icon: 'referente-dedicato.svg',
    key: '2'
  },
  {
    caption: 'Il precedente fornitore ti restituirà la cauzione versata',
    icon: 'cauzione.svg',
    key: '3'
  }
]