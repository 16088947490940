import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose } from 'redux'

import { selectIsFetchingSignersData, selectCurrentSigner, selectAlreadySignedData } from '../../../redux/subscription/subscription.selectors'
import WithSpinner from '../../WithSpinner/WithSpinner.component'
import OtpSigners from './OtpSigners.component'

const mapStateToProps = createStructuredSelector({
  isLoading: selectIsFetchingSignersData,
  currentSigner: selectCurrentSigner,
  alreadySignedData: selectAlreadySignedData,
  otherClasses: () => 'component-loader'
})

const OtpSignersContainer = compose(
  connect(mapStateToProps),
  WithSpinner
)(OtpSigners)

export default OtpSignersContainer
