import React from 'react'
import { Field } from 'react-final-form'
import { FormGroup, FormFeedback, Label } from 'reactstrap'
import classnames from 'classnames'
import AsyncSelect from 'react-select/async'

import ExtendedAutocompleteFormFieldStyle from './style'

const ExtendedAutocompleteFormField = ({ children, ...props }) => {
  return (
    <ExtendedAutocompleteFormFieldStyle>
      <FormGroup>
        <Field {...props}>
          {({ isMulti = false, fetchFunction, fieldLabel, fieldDescription, noValidationMessage, placeholder,
            input: { name, value, onChange, onBlur }, meta: { error, touched }, options, className, ...input }) => (
            <>
              <AsyncSelect
                className={`${className}${error && touched ? ' is-invalid' : (!error && touched ? ' is-valid' : '')}`}
                value={value}
                placeholder={placeholder || null}
                isMulti={isMulti}
                isClearable={true}
                onChange={value => onChange(value)}
                onBlur={onBlur}
                loadOptions={value => fetchFunction(value)}
                {...input}
              />
              {fieldLabel &&
                <Label for={name} className={`${value ? 'shrink' : ''} form-input-label`}>{fieldLabel}</Label>
              }
              <FormFeedback className={classnames({ 'd-flex': error && touched })}>{error}</FormFeedback>
            </>
          )}
        </Field>
      </FormGroup>
    </ExtendedAutocompleteFormFieldStyle>
  )
}

export default ExtendedAutocompleteFormField
