import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import PaymentForm from './PaymentForm.component'
import WithSpinner from '../../WithSpinner/WithSpinner.component'
import { fetchBankAccountDefaultValuesStart } from '../../../redux/subscription/subscription.actions'
import { selectIsFetchingPaymentData, selectBankAccountDetailId } from '../../../redux/subscription/subscription.selectors'

const PaymentFormWithSpinner = WithSpinner(PaymentForm)

const PaymentFormContainer = () => {
  const dispatch = useDispatch()
  const bankAccountDetailId = useSelector(selectBankAccountDetailId)
  const isFetchingData = useSelector(selectIsFetchingPaymentData)
  useEffect(() => {
    if(bankAccountDetailId) dispatch(fetchBankAccountDefaultValuesStart({ bankAccountDetailId }))
  }, [dispatch, bankAccountDetailId])

  return (
    <PaymentFormWithSpinner otherClasses="component-loader form-loader" isLoading={isFetchingData} />
  )
}

export default PaymentFormContainer
