import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

import DomesticText from './DomesticText.component'
import BusinessText from './BusinessText.component'

const GeneralConditionsModal = ({ modalVisibility, toggleModal, customerType }) => (
  <Modal className="form-modal privacy-modal" size="lg" backdrop="static" isOpen={modalVisibility} toggle={toggleModal}>
    <ModalHeader toggle={toggleModal}>
      CONDIZIONI GENERALI DI CONTRATTO
    </ModalHeader>
    <ModalBody>
      {customerType === 'domestic' &&
        <DomesticText />
      }
      {customerType === 'business' &&
        <BusinessText />
      }
    </ModalBody>
    <ModalFooter>
      <Button className="ab-button" onClick={toggleModal}>Chiudi</Button>
    </ModalFooter>
  </Modal>
)

export default GeneralConditionsModal
