import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import ContactForm from './ContactForm.component'
import WithSpinner from '../../WithSpinner/WithSpinner.component'
import { fetchContactDefaultValuesStart } from '../../../redux/subscription/subscription.actions'
import { selectIsFetchingContactData, selectContactId } from '../../../redux/subscription/subscription.selectors'

const ContactFormWithSpinner = WithSpinner(ContactForm)

const ContactFormContainer = () => {
  const dispatch = useDispatch()
  const contactId = useSelector(selectContactId)
  const isFetchingData = useSelector(selectIsFetchingContactData)

  useEffect(() => {
    if(contactId) dispatch(fetchContactDefaultValuesStart({ contactId }))
  }, [dispatch, contactId])

  return (
    <ContactFormWithSpinner otherClasses="component-loader form-loader" isLoading={isFetchingData} />
  )
}

export default ContactFormContainer
