import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { Form } from 'react-final-form'
import { Button } from 'reactstrap'

import { required } from '../validations'
import { selectIsSubmitting, selectContractPdf } from '../../../redux/subscription/subscription.selectors'
import { fetchOtpCodeStart, updateFormStep } from '../../../redux/subscription/subscription.actions'
import CustomFormField from '../CustomFormField/CustomFormField.component'
import ButtonWithLoader from '../../ButtonWithLoader/ButtonWithLoader.component'
import SubscriptionStepsMap from '../../../pages/SubscriptionPage/subscriptionSteps'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const RequestOtpCodeForm = ({ signer, isSubmitting, fetchOtpCodeStart, handleFormStep, contractPdfUrl }) => {
  const { id, name, surname, mobilePhone, fiscalCode } = signer
  const onSubmit = values => fetchOtpCodeStart({ values })

  return (
    <div className="request-otp-code-form">
      <Form
        onSubmit={onSubmit}
        initialValues={{
          signerId: id
        }}
      >
        {({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit} autoComplete="off">
            <CustomFormField
              id="mobilePhone"
              name="mobilePhone"
              type="text"
              validate={required}
              defaultValue={mobilePhone}
              placeholder="Numero cellulare"
              className="ab-input"
              maxLength="20"
              focusOnMount={true}
              disabled={isSubmitting}
            />
            <CustomFormField
              id="name"
              name="name"
              type="text"
              defaultValue={name}
              placeholder="Nome"
              className="ab-input"
              readOnly
            />
            <CustomFormField
              id="surname"
              name="surname"
              type="text"
              defaultValue={surname}
              placeholder="Cognome"
              className="ab-input"
              readOnly
            />
            <CustomFormField
              id="fiscalCode"
              name="fiscalCode"
              type="text"
              defaultValue={fiscalCode}
              placeholder="Codice fiscale"
              className="ab-input"
              readOnly
            />

            <div className="otp-info">
              <FontAwesomeIcon icon={['fas', 'info-circle']} />
              <p>La generazione del codice potrebbe richiedere qualche secondo. A breve riceverai un SMS al numero indicato sopra.</p>
            </div>

            <div className="read-contract">
              <h6>Vuoi leggere il contratto?</h6>
              <a 
                href={contractPdfUrl}
                target="_blank" 
                rel="noreferrer"
              >
                Scaricalo qui
              </a>
            </div>

            <div className="form-buttons">
              <Button 
                className="step-back"
                color="link"
                onClick={() => handleFormStep(SubscriptionStepsMap.REVIEW_STEP)}
              >
                Indietro
              </Button>
              <ButtonWithLoader
                type="submit"
                otherclasses="ab-button"
                buttonLabel="Avanti"
                onClick={handleSubmit}
                isFetching={isSubmitting}
                disabled={isSubmitting}
              />
            </div>
          </form>
        )}
      </Form>
    </div>
  )
}

const mapStateToProps = createStructuredSelector({
  isSubmitting: selectIsSubmitting,
  contractPdfUrl: selectContractPdf
})

const mapDispatchToProps = dispatch => ({
  fetchOtpCodeStart: data => dispatch(fetchOtpCodeStart(data)),
  handleFormStep: formStep => dispatch(updateFormStep(formStep))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RequestOtpCodeForm)
