import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import SupplyPointsForm from './SupplyPointsForm.component'
import WithSpinner from '../../WithSpinner/WithSpinner.component'
import { fetchSupplyPointsDefaultValuesStart } from '../../../redux/subscription/subscription.actions'
import { selectIsFetchingSupplyPointsData, selectPodId, selectPdrId } from '../../../redux/subscription/subscription.selectors'

const SupplyPointsFormWithSpinner = WithSpinner(SupplyPointsForm)

const SupplyPointsFormContainer = () => {
  const dispatch = useDispatch()
  const podId = useSelector(selectPodId)
  const pdrId = useSelector(selectPdrId)
  const isFetchingData = useSelector(selectIsFetchingSupplyPointsData)
  useEffect(() => {
    if(podId || pdrId) dispatch(fetchSupplyPointsDefaultValuesStart({ podId, pdrId }))
  }, [dispatch, podId, pdrId])

  return (
    <SupplyPointsFormWithSpinner otherClasses="component-loader form-loader" isLoading={isFetchingData} />
  )
}

export default SupplyPointsFormContainer
