import React from 'react'
import { FormGroup } from 'reactstrap'
import { Field } from 'react-final-form'

const CustomSwitchField = ({ onClick, ...props }) => (
  <FormGroup className="custom-switch-field">
    <Field
      {...props}
      type="checkbox"
    >
      {({ input, meta, label, valueLabel, disabled, ...otherProps }) => (
        <div
          className="ab-input custom-switch custom-control"
        >
            <p>{label}</p>
            <div className="switch-input-container">
              <input
                id={input.name}
                className="custom-control-input"
                {...input}
                disabled={disabled}
              />
              <label 
                className="custom-control-label" 
                htmlFor={input.name}
                onClick={!disabled && onClick ? onClick : null}
              >
                {valueLabel}
              </label>
            </div>
        </div>
      )}
    </Field>
  </FormGroup>
)

export default CustomSwitchField
