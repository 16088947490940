import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { selectContractType } from '../../../redux/subscription/subscription.selectors'
import DomesticProposalForm from './DomesticProposalForm.component'
import BusinessProposalForm from './BusinessProposalForm.component'

const ProposalForm = ({ contractType }) => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [])

  return (
    <div className="form-container">
      {contractType === 'DOM' &&
        <DomesticProposalForm />
      }
      {contractType === 'BUS' &&
        <BusinessProposalForm />
      }
    </div>
  )
}

const mapStateToProps = createStructuredSelector({
  contractType: selectContractType
})

export default connect(
  mapStateToProps
)(ProposalForm)
