import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose } from 'redux'

import { selectIsFetchingContractData, selectReviewData } from '../../redux/subscription/subscription.selectors'
import WithSpinner from '../WithSpinner/WithSpinner.component'
import BusinessReview from './BusinessReview.component'

const mapStateToProps = createStructuredSelector({
  isLoading: selectIsFetchingContractData,
  reviewData: selectReviewData,
  otherClasses: () => 'component-loader'
})

const BusinessReviewContainer = compose(
  connect(mapStateToProps),
  WithSpinner
)(BusinessReview)

export default BusinessReviewContainer
