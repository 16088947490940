//!IMPORTANT This component presumes you're using react-final-form
import React from 'react'
import { Field } from 'react-final-form'
import { FormGroup, Input, FormFeedback, Label } from 'reactstrap'

import './style.scss'

const CustomCheckboxField = ({ children, ...props }) => (
  <FormGroup check>
    <Field {...props} type="checkbox">
      {({ label, input, meta: { error, touched }, ...otherProps }) => (
        <>
          <Label check for={input.name}>
            <Input invalid={error && touched ? true : false} {...input} {...otherProps} />
            {children}
            <FormFeedback>{error}</FormFeedback>
          </Label>
        </>
      )}
    </Field>
  </FormGroup>
)

export default CustomCheckboxField
