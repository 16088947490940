import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/analytics'

import confJson from '../api/configuration.json'

const config = {
  "development": {
    apiKey: "AIzaSyAq72SnPJjGbvBIfvMr4fQJ31pZSa2K95s",
    authDomain: "store-portal-development.firebaseapp.com",
    projectId: "store-portal-development",
    storageBucket: "store-portal-development.appspot.com",
    messagingSenderId: "319070797366",
    appId: "1:319070797366:web:6cededd79f4db8399e6297",
    measurementId: "G-FEFRH9EHLW"
  },
  "production": {
    apiKey: "AIzaSyCgtTxeyGzmmBRBJZoJufZ2umfTJDiRblc",
    authDomain: "store-portal-prod.firebaseapp.com",
    projectId: "store-portal-prod",
    storageBucket: "store-portal-prod.appspot.com",
    messagingSenderId: "667022757632",
    appId: "1:667022757632:web:9366189759fee31e186de5",
    measurementId: "G-03M9Q6RES8"
  }
}

firebase.initializeApp(config[confJson.env])
if(confJson.env === 'production') firebase.analytics()
export const auth = firebase.auth()

export const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged(userAuth => {
      unsubscribe()
      resolve(userAuth)
    }, reject)
  })
}

export const refreshFirebaseToken = firebaseUser => firebaseUser.getIdToken()

export const signOut = () => auth.signOut()

export default firebase
