import React from 'react'
import { ListGroupItem } from 'reactstrap'

const ReviewData = ({ label, value }) => (
  <ListGroupItem>
    <strong>{label}:</strong>{' '}
    <span>{value}</span>
  </ListGroupItem>
)

export default ReviewData
