import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import queryString from 'query-string'
import classnames from 'classnames'

import { fetchContractStart } from '../../redux/subscription/subscription.actions'
import { selectContractClosed } from '../../redux/subscription/subscription.selectors'
import SubscriptionStepsContainer from '../../components/SubscriptionSteps/SubscriptionStepsContainer.component'
import PreFooter from '../../components/PreFooter/PreFooter.component'
import Footer from '../../components/Footer/Footer.component'
import ThankYou from '../../components/ThankYou/ThankYou.component'

const SubscriptionPage = ({ contractClosed, fetchContractStart, location: { search }, match: { params } }) => {
  const { contactId, secureCode } = params
  const { e, g } = queryString.parse(search)
  useEffect(() => {
    fetchContractStart({ e, g, contactId, secureCode })
  }, [fetchContractStart, e, g, contactId, secureCode])

  return (
    <>
      <div className={classnames({
        'main-content-wrapper': true,
        'contract-closed': contractClosed === true
      })}>
        <SubscriptionStepsContainer otherClasses="with-bkg" />
      </div>
      {contractClosed === true &&
        <ThankYou />
      }
      <PreFooter />
      <Footer />
    </>
  )
}

const mapStateToProps = createStructuredSelector({
  contractClosed: selectContractClosed
})

const mapDispatchToProps = dispatch => ({
  fetchContractStart: data => dispatch(fetchContractStart(data))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionPage)
