import styled from 'styled-components'

const ExtendedSelectFormFieldStyle = styled.div`
  .extended-select {
    outline: none !important;
  }

  .extended-select__control {
    border: 1px solid #0065a3;
    outline: none !important;
    border-radius: 5px;
    box-shadow: none;
    min-height: 45px;

    &:focus,
    &:hover {
      border-color: #0065a3;
    }
    &.extended-select__control--is-focused {
      box-shadow: 0 0 0 1px #0065a3;
    }
  }

  .extended-select__menu-list {
    max-height: 200px;
  }

  .extended-select__value-container {
    padding: .55rem 1rem;
    font-size: .85rem;
  }

  .extended-select__input {
    color: #0065a3;
  }

  .extended-select__single-value,
  .extended-select__placeholder {
    font-size: .85rem;
    color: #0065a3;
  }

  .extended-select__placeholder {
    color: rgba(0, 101, 163, .5);
  }

  .extended-select__indicator {
    color: #0065a3;
  }

  .extended-select__indicator-separator {
    background-color: #ddd;
  }

  .extended-select__option {
    font-size: .85rem;
    color: #0065a3;

    &.extended-select__option--is-selected {
      background-color: rgba(0, 101, 163, .5);
      color: #fff;
    }

    &.extended-select__option--is-focused {
      background-color: #0065a3;
      color: #fff;
    }
  }

  .form-input-label {
    z-index: -1;
  }
`

export default ExtendedSelectFormFieldStyle
