const SubscriptionTypes = {
  ADD_CONTRACT: 'ADD_CONTRACT',
  REMOVE_CONTRACT: 'REMOVE_CONTRACT',
  UPDATE_FORM_STEP: 'UPDATE_FORM_STEP',
  TOGGLE_OTP_MODAL_VISIBILITY: 'TOGGLE_OTP_MODAL_VISIBILITY',
  TOGGLE_UTILITY_MODAL_VISIBILITY: 'TOGGLE_UTILITY_MODAL_VISIBILITY',
  SET_UTILITY_MODAL_CONTENT: 'SET_UTILITY_MODAL_CONTENT',
  TOGGLE_OTP_SIGNATURE_MODAL: 'TOGGLE_OTP_SIGNATURE_MODAL',
  TOGGLE_POD_GREEN_ENERGY_RESUME_BOX: 'TOGGLE_POD_GREEN_ENERGY_RESUME_BOX',
  TOGGLE_PDR_GREEN_ENERGY_RESUME_BOX: 'TOGGLE_PDR_GREEN_ENERGY_RESUME_BOX',
  TOGGLE_TOOLTIP: 'TOGGLE_TOOLTIP',

  REFRESH_USER_TOKEN_START: 'REFRESH_USER_TOKEN_START',
  REFRESH_USER_TOKEN_SUCCESS: 'REFRESH_USER_TOKEN_SUCCESS',
  REFRESH_USER_TOKEN_FAILURE: 'REFRESH_USER_TOKEN_FAILURE',

  FETCH_CONTRACT_START: 'FETCH_CONTRACT_START',
  FETCH_CONTRACT_SUCCESS: 'FETCH_CONTRACT_SUCCESS',
  FETCH_CONTRACT_FAILURE: 'FETCH_CONTRACT_FAILURE',

  FETCH_CONTACT_DEFAULT_VALUES_START: 'FETCH_CONTACT_DEFAULT_VALUES_START',
  FETCH_CONTACT_DEFAULT_VALUES_SUCCESS: 'FETCH_CONTACT_DEFAULT_VALUES_SUCCESS',
  FETCH_CONTACT_DEFAULT_VALUES_FAILURE: 'FETCH_CONTACT_DEFAULT_VALUES_FAILURE',

  FETCH_CHANGE_SUPPLIER_DEFAULT_VALUES_START: 'FETCH_CHANGE_SUPPLIER_DEFAULT_VALUES_START',
  FETCH_CHANGE_SUPPLIER_DEFAULT_VALUES_SUCCESS: 'FETCH_CHANGE_SUPPLIER_DEFAULT_VALUES_SUCCESS',
  FETCH_CHANGE_SUPPLIER_DEFAULT_VALUES_FAILURE: 'FETCH_CHANGE_SUPPLIER_DEFAULT_VALUES_FAILURE',

  FETCH_BANK_ACCOUNT_DEFAULT_VALUES_START: 'FETCH_BANK_ACCOUNT_DEFAULT_VALUES_START',
  FETCH_BANK_ACCOUNT_DEFAULT_VALUES_SUCCESS: 'FETCH_BANK_ACCOUNT_DEFAULT_VALUES_SUCCESS',
  FETCH_BANK_ACCOUNT_DEFAULT_VALUES_FAILURE: 'FETCH_BANK_ACCOUNT_DEFAULT_VALUES_FAILURE',

  FETCH_CADASTRAL_DEFAULT_VALUES_START: 'FETCH_CADASTRAL_DEFAULT_VALUES_START',
  FETCH_CADASTRAL_DEFAULT_VALUES_SUCCESS: 'FETCH_CADASTRAL_DEFAULT_VALUES_SUCCESS',
  FETCH_CADASTRAL_DEFAULT_VALUES_FAILURE: 'FETCH_CADASTRAL_DEFAULT_VALUES_FAILURE',

  FETCH_SUPPLY_POINTS_DEFAULT_VALUES_START: 'FETCH_SUPPLY_POINTS_DEFAULT_VALUES_START',
  FETCH_SUPPLY_POINTS_DEFAULT_VALUES_SUCCESS: 'FETCH_SUPPLY_POINTS_DEFAULT_VALUES_SUCCESS',
  FETCH_SUPPLY_POINTS_DEFAULT_VALUES_FAILURE: 'FETCH_SUPPLY_POINTS_DEFAULT_VALUES_FAILURE',

  SUBMIT_CONTACT_FORM_START: 'SUBMIT_CONTACT_FORM_START',
  SUBMIT_CONTACT_FORM_SUCCESS: 'SUBMIT_CONTACT_FORM_SUCCESS',
  SUBMIT_CONTACT_FORM_FAILURE: 'SUBMIT_CONTACT_FORM_FAILURE',

  SUBMIT_PROPOSAL_FORM_START: 'SUBMIT_PROPOSAL_FORM_START',
  SUBMIT_PROPOSAL_FORM_SUCCESS: 'SUBMIT_PROPOSAL_FORM_SUCCESS',
  SUBMIT_PROPOSAL_FORM_FAILURE: 'SUBMIT_PROPOSAL_FORM_FAILURE',

  SUBMIT_PAYMENT_FORM_START: 'SUBMIT_PAYMENT_FORM_START',
  SUBMIT_PAYMENT_FORM_SUCCESS: 'SUBMIT_PAYMENT_FORM_SUCCESS',
  SUBMIT_PAYMENT_FORM_FAILURE: 'SUBMIT_PAYMENT_FORM_FAILURE',

  SUBMIT_CADASTRAL_FORM_START: 'SUBMIT_CADASTRAL_FORM_START',
  SUBMIT_CADASTRAL_FORM_SUCCESS: 'SUBMIT_CADASTRAL_FORM_SUCCESS',
  SUBMIT_CADASTRAL_FORM_FAILURE: 'SUBMIT_CADASTRAL_FORM_FAILURE',

  SUBMIT_SUPPLY_POINTS_FORM_START: 'SUBMIT_SUPPLY_POINTS_FORM_START',
  SUBMIT_SUPPLY_POINTS_FORM_SUCCESS: 'SUBMIT_SUPPLY_POINTS_FORM_SUCCESS',
  SUBMIT_SUPPLY_POINTS_FORM_FAILURE: 'SUBMIT_SUPPLY_POINTS_FORM_FAILURE',
  SUBMIT_POD_FORM_SUCCESS: 'SUBMIT_POD_FORM_SUCCESS',
  SUBMIT_PDR_FORM_SUCCESS: 'SUBMIT_PDR_FORM_SUCCESS',
  DELETE_POD_SUCCESS: 'DELETE_POD_SUCCESS',
  DELETE_PDR_SUCCESS: 'DELETE_PDR_SUCCESS',

  FETCH_CONTRACT_REVIEW_DATA_START: 'FETCH_CONTRACT_REVIEW_DATA_START',
  FETCH_CONTRACT_REVIEW_DATA_SUCCESS: 'FETCH_CONTRACT_REVIEW_DATA_SUCCESS',
  FETCH_CONTRACT_REVIEW_DATA_FAILURE: 'FETCH_CONTRACT_REVIEW_DATA_FAILURE',

  FETCH_OTP_SIGNERS_DATA_START: 'FETCH_OTP_SIGNERS_DATA_START',
  FETCH_OTP_SIGNERS_DATA_SUCCESS: 'FETCH_OTP_SIGNERS_DATA_SUCCESS',
  FETCH_OTP_SIGNERS_DATA_FAILURE: 'FETCH_OTP_SIGNERS_DATA_FAILURE',

  FETCH_OTP_CODE_START: 'FETCH_OTP_CODE_START',
  FETCH_OTP_CODE_SUCCESS: 'FETCH_OTP_CODE_SUCCESS',
  FETCH_OTP_CODE_FAILURE: 'FETCH_OTP_CODE_FAILURE',

  SUBMIT_OTP_SIGNATURE_START: 'SUBMIT_OTP_SIGNATURE_START',
  SUBMIT_OTP_SIGNATURE_SUCCESS: 'SUBMIT_OTP_SIGNATURE_SUCCESS',
  SUBMIT_OTP_SIGNATURE_FAILURE: 'SUBMIT_OTP_SIGNATURE_FAILURE',

  SAVE_ONGOING_LAST_STEP_VALUES: 'SAVE_ONGOING_LAST_STEP_VALUES',
}

export default SubscriptionTypes
