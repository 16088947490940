import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose } from 'redux'

import { selectIsFetchingContractData, selectReviewData } from '../../redux/subscription/subscription.selectors'
import WithSpinner from '../WithSpinner/WithSpinner.component'
import DomesticReview from './DomesticReview.component'

const mapStateToProps = createStructuredSelector({
  isLoading: selectIsFetchingContractData,
  reviewData: selectReviewData,
  otherClasses: () => 'component-loader form-loader'
})

const DomesticReviewContainer = compose(
  connect(mapStateToProps),
  WithSpinner
)(DomesticReview)

export default DomesticReviewContainer
