export const updateFieldValue = ([name, newValue], state, { changeValue }) => {
  changeValue(state, name, () => newValue)
}

export const resetPodContractPriceListCode = ([name, newValue], state, { changeValue }) => {
  changeValue(state, 'podContractPriceListCode', () => null)
}

export const resetPdrContractPriceListCode = ([name, newValue], state, { changeValue }) => {
  changeValue(state, 'pdrContractPriceListCode', () => null)
}