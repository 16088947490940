import React, { useState } from 'react'
import { Toast, ToastBody, ToastHeader, Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ReminderToast = () => {
  const [ show, setShow ] = useState(true)
  const toggle = () => setShow(!show)

  return (
    <Toast id="reminder-toast" isOpen={show}>
        <ToastHeader>
          <div className="d-flex flex-grow-1 align-items-center">
            <span className="title">Ti consigliamo di tenere a portata di mano:</span>
          </div>
        </ToastHeader>
        <ToastBody>
          <section className="form-reminder">
            <ul>
              <li>
                <FontAwesomeIcon icon={['fas', 'check']} className="icon mr-2 animated fadeInLeft" />
                <span>il tuo codice fiscale</span>
              </li>
              <li>
                <FontAwesomeIcon icon={['fas', 'check']} className="icon mr-2 animated fadeInLeft" />
                <span>una bolletta</span>
              </li>
              <li>
                <FontAwesomeIcon icon={['fas', 'check']} className="icon mr-2 animated fadeInLeft" />
                <span>codice IBAN</span>
              </li>
            </ul>
          </section>
          <hr />
          <div className="text-right">
            <Button className="ab-button" onClick={toggle}>Ho capito</Button>
          </div>
        </ToastBody>
      </Toast>
  )
}

export default ReminderToast
