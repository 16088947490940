import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { Container, Row, Col } from 'reactstrap'

import SubscriptionStepsMap from '../../pages/SubscriptionPage/subscriptionSteps'
import ContactFormContainer from '../forms/ContactForm/ContactFormContainer.component'
import ProposalFormContainer from '../forms/ProposalForm/ProposalFormContainer.component'
import PaymentFormContainer from '../forms/PaymentForm/PaymentFormContainer.component'
import CadastralFormContainer from '../forms/CadastralForm/CadastralFormContainer.component'
import SupplyPointsFormContainer from '../forms/SupplyPointsForm/SupplyPointsFormContainer.component'
import ReviewStep from '../ReviewStep/ReviewStep.component'
import OtpStep from '../forms/OtpStep/OtpStep.component'
import PageHeader from '../PageHeader/PageHeader.component'
import ResumeBox from '../ResumeBox/ResumeBox.component'
import { selectFormStep } from '../../redux/subscription/subscription.selectors'

const SubscriptionSteps = ({ formStep }) => (
  <section className="page">
    <PageHeader />
    <Container className="contract-container">
      <Row>
        <Col xs={{ size: 12, order: 2 }} md={{ size: 5, order: 1 }} lg={{ offset: 1 }}>
          {formStep === SubscriptionStepsMap.CONTACT_STEP &&
            <ContactFormContainer />
          }
          {formStep === SubscriptionStepsMap.PROPOSAL_STEP &&
            <ProposalFormContainer />
          }
          {formStep === SubscriptionStepsMap.PAYMENT_STEP &&
            <PaymentFormContainer />
          }
          {formStep === SubscriptionStepsMap.CADASTRAL_STEP &&
            <CadastralFormContainer />
          }
          {formStep === SubscriptionStepsMap.SUPPLY_POINTS_STEP &&
            <SupplyPointsFormContainer />
          }
          {formStep === SubscriptionStepsMap.REVIEW_STEP &&
            <ReviewStep />
          }
          {formStep === SubscriptionStepsMap.OTP_STEP &&
            <OtpStep />
          }
        </Col>
        <Col xs={{ size: 12, order: 1 }} md={{ size: 6, offset: 1, order: 2 }} lg={{ size: 4 }}>
          <ResumeBox />
        </Col>
      </Row>
    </Container>
  </section>
)

const mapStateToProps = createStructuredSelector({
  formStep: selectFormStep
})

export default connect(mapStateToProps)(SubscriptionSteps)
