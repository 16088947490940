import React from 'react'
import { Container, Navbar, NavbarBrand } from 'reactstrap'

import { ReactComponent as Logo } from '../../assets/svg/logo-ab.svg'
import ContractTitle from '../ContractTitle/ContractTitle.component'
import ReassuranceSlider from '../ReassuranceSlider/ReassuranceSlider.component'

const PageHeader = () => (
  <header className="nav-header">
    <Navbar id="page-header">
      <Container>
        <NavbarBrand>
          <Logo />
        </NavbarBrand>
        <ReassuranceSlider />
      </Container>
    </Navbar>
    <Container>
      <ContractTitle />
    </Container>
  </header>
)

export default PageHeader
