import React, { useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { Form, FormSpy } from 'react-final-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, CustomInput } from 'reactstrap'
import classnames from 'classnames'

import { required, isValidPod, positiveNumber, requiredLength } from '../validations'
import FormLabels from '../formLabels'
import {
  selectIsSubmitting, selectContractType, selectCadastralDataId, selectFormFieldsValues,
  selectAddOnContract, selectContractInfo, selectEnergyOffer, selectGasOffer,
  selectSupplyPointsDefaultValues, selectBankAccountDetailId, selectAccessToken,
  selectOngoingLastStepValues, selectFormStep, selectAllowedStep
} from '../../../redux/subscription/subscription.selectors'
import {
  submitSupplyPointsFormStart, addContract, removeContract, 
  updateFormStep, saveOngoingLastStepValues
} from '../../../redux/subscription/subscription.actions'
import WhenFieldChanges from '../WhenFieldChanges/WhenFieldChanges.component'
import CustomFormField from '../CustomFormField/CustomFormField.component'
import ExtendedSelectField from '../ExtendedSelectField/ExtendedSelectField.component'
import ExtendedCustomZipCodeSelect from '../ExtendedCustomZipCodeSelect/ExtendedCustomZipCodeSelect.component'
import CustomSwitchField from '../CustomSwitchField/CustomSwitchField.component'
import ExtendedAutocompleteFormField from '../ExtendedAutocompleteFormField/ExtendedAutocompleteFormField.component'
import ButtonWithLoader from '../../ButtonWithLoader/ButtonWithLoader.component'
import PodInfoModal from '../PodInfoModal/PodInfoModal.component'
import PdrInfoModal from '../PdrInfoModal/PdrInfoModal.component'
import GasUseModal from '../GasUseModal/GasUseModal.component'
import PowerValueModal from '../PowerValueModal/PowerValueModal.component'
import { fetchVendors, fetchTowns } from '../../../api'
import { resetPodContractPriceListCode, resetPdrContractPriceListCode } from './utils'
import SubscriptionStepsMap from '../../../pages/SubscriptionPage/subscriptionSteps'

const SupplyPointsForm = ({
  isSubmitting, contractType, contractInfo, addOnContract, energyOffer, gasOffer,
  cadastralDataId, submitSupplyPointsForm, addDualContract, removeDualContract, formFieldsValues,
  defaultValues, bankAccountDetailId, accessToken, ongoingLastStepValues,
  handleFormStep, saveFormValues, formStep, allowedStep
}) => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [])

  const onSubmit = values => submitSupplyPointsForm({ values })

  const addOffer = fornitureType => {
    addDualContract()
    if(fornitureType === 'E') window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }
  const removeOffer = fornitureType => {
    removeDualContract()
  }

  const defaultEletricUseTypeId = useMemo(() => contractType === 'DOM' ? { value: "1", label: "Domestico" } : null, [contractType])
  const defaultGasUseTypeId = useMemo(() => contractType === 'DOM' ? { value: "1", label: "Domestico" } : null, [contractType])
  const defaultPodContractPriceListCode = useMemo(() => energyOffer ? { value: energyOffer.code, label: energyOffer.name } : null, [energyOffer])
  const defaultPdrContractPriceListCode = useMemo(() => gasOffer ? { value: gasOffer.code, label: gasOffer.name } : null, [gasOffer])
  const formValues = defaultValues || ongoingLastStepValues

  return (
    <div className="form-container">
      <Form
        onSubmit={onSubmit}
        mutators={{
          resetPodContractPriceListCode,
          resetPdrContractPriceListCode
        }}
        initialValues={{
          ...formValues,
          cadastralDataId,
          bankAccountDetailId,
          electricUseTypeId: formValues?.electricUseTypeId || defaultEletricUseTypeId,
          gasUseTypeId: formValues?.gasUseTypeId || defaultGasUseTypeId,
          billingShippingAddress: formValues?.billingShippingAddress ?? true,
          pdrContractPriceListCode: formValues?.pdrContractPriceListCode || defaultPdrContractPriceListCode,
          podContractPriceListCode: formValues?.podContractPriceListCode || defaultPodContractPriceListCode
        }}
        validate={values => {
          if(values.billingShippingAddress?.value === false) {
            const errors = {}
            if(!values.atName) errors.atName = FormLabels.REQUIRED
            if(!values.toponymId) errors.toponymId = FormLabels.REQUIRED
            if(!values.streetName) errors.streetName = FormLabels.REQUIRED
            if(!values.streetNo) errors.streetNo = FormLabels.REQUIRED
            if(!values.townName) errors.townName = FormLabels.REQUIRED
            if(!values.postalCode) errors.postalCode = FormLabels.REQUIRED
            return errors
          }
        }}
      >
        {({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit} className="login-form" autoComplete="off">
            <WhenFieldChanges
              field="townName"
              set="postalCode"
              to={null}
            />
            <CustomFormField
              name="cadastralDataId"
              type="hidden"
            />
            <CustomFormField
              name="bankAccountDetailId"
              type="hidden"
            />
            {energyOffer &&
              <section className="form-section">
                <div className="form-header">
                  <h3 className="form-title">
                    <FontAwesomeIcon icon={['fas', 'bolt']} className="icon" />
                    Dati fornitura luce
                  </h3>
                </div>
                <CustomFormField
                  id="podNo"
                  name="podNo"
                  type="text"
                  validate={isValidPod}
                  placeholder="Codice POD"
                  className="ab-input"
                  maxLength="14"
                  focusOnMount={true}
                >
                  <PodInfoModal
                    title="Cosa è il codice POD?"
                    description="Il codice POD identifica l’esatto punto di prelievo dell’energia elettrica dalla rete nazionale, esso non cambia mai, rimane lo stesso anche se si cambia fornitore.<br /> Si trova solitamente nel primo foglio della bolletta ed è composto da 14-15 caratteri alfanumerici. Se il tuo codice POD presenta 15 caratteri, INSERISCI SOLAMENTE I PRIMI 14.<br />Esempio di codice POD: IT 123 E 12345678"
                    icon={['fas', 'bolt']}
                  />
                </CustomFormField>
                <CustomFormField
                  id="powerValue"
                  name="powerValue"
                  maxLength="80"
                  step=".01"
                  min="0"
                  type="number"
                  validate={positiveNumber}
                  placeholder="Potenza disponibile in KW"
                  className="ab-input"
                >
                  <PowerValueModal />
                </CustomFormField>
                <ExtendedSelectField
                  id="connectionVoltageId"
                  name="connectionVoltageId"
                  options={formFieldsValues.connectionVoltage}
                  validate={required}
                  placeholder="Tensione di allacciamento"
                  className="extended-select"
                  classNamePrefix="extended-select"
                />
                {contractType === 'BUS' &&
                  <ExtendedSelectField
                    id="electricUseTypeId"
                    name="electricUseTypeId"
                    options={formFieldsValues.electricUseType}
                    validate={required}
                    placeholder="Tipo d'uso"
                    className="extended-select"
                    classNamePrefix="extended-select"
                  />
                }

                {contractType === 'DOM' &&
                  <CustomSwitchField
                    className="ab-input"
                    id="podIsResident"
                    name="podIsResident"
                    initialValue={defaultValues && defaultValues.podIsResident !== null ? defaultValues.podIsResident : true}
                    defaultChecked={values.podIsResident}
                    label="Residente"
                    valueLabel={values.podIsResident === true ? 'Sì' : 'No'}
                  />
                }
                {addOnContract && (addOnContract.code !== energyOffer.code) &&
                  <div className="form-header apply-to">
                    <FontAwesomeIcon icon={['fas', 'fire']} className="icon" />
                    <div className="switch-input-container ab-input">
                      <p>Attiva {contractInfo.applyTo.name}</p>
                      <CustomInput
                        type="switch"
                        id="podAddOfferSwitch"
                        name="podAddOfferSwitch"
                        defaultChecked={formValues?.pdrContractPriceListCode && formValues.pdrContractPriceListCode !== null ? true : false}
                        onChange={(item) => {
                          if(item.target.checked === true) {
                            addOffer('G')
                          }
                          else {
                            removeOffer('G')
                            form.mutators.resetPdrContractPriceListCode()
                          }
                        }}
                      />
                    </div>
                  </div>
                }
              </section>
            }

            {gasOffer &&
              <section className="form-section">
                <div className="form-header">
                  <h3 className="form-title">
                    <FontAwesomeIcon icon={['fas', 'fire']} className="icon" />
                    Dati fornitura gas
                  </h3>
                </div>
                <CustomFormField
                  id="pdrNo"
                  name="pdrNo"
                  type="text"
                  validate={requiredLength(14)}
                  placeholder="Codice PDR"
                  className="ab-input"
                  maxLength="14"
                  focusOnMount={energyOffer ? false : true}
                >
                  <PdrInfoModal />
                </CustomFormField>
                <CustomFormField
                  id="annualConsumption"
                  name="annualConsumption"
                  type="number"
                  maxLength="80"
                  validate={positiveNumber}
                  placeholder="Consumo annuo (smc)"
                  className="ab-input"
                />
                <ExtendedAutocompleteFormField
                  id="vendorName"
                  name="vendorName"
                  fetchFunction={fetchVendors(accessToken)}
                  minChars={2}
                  fieldLabel="Attuale fornitore"
                  placeholder="Attuale fornitore"
                  type="text"
                  maxLength="80"
                  validate={required}
                  className="extended-autocomplete"
                  classNamePrefix="extended-autocomplete"
                  noOptionsMessage={() => 
                    <span className="autocomplete-suggestion">Indica le prime lettere dell'attuale fornitore per trovarlo nella lista</span>
                  }
                />
                <ExtendedSelectField
                  id="gasUseCategoryCode"
                  name="gasUseCategoryCode"
                  options={formFieldsValues.gasUseCategory}
                  validate={required}
                  placeholder="Categoria uso AEEG"
                  className="extended-select"
                  classNamePrefix="extended-select"
                >
                  <GasUseModal />
                </ExtendedSelectField>
                {contractType === 'BUS' &&
                  <ExtendedSelectField
                    id="gasUseTypeId"
                    name="gasUseTypeId"
                    options={formFieldsValues.gasUseType}
                    validate={required}
                    placeholder="Tipo d'uso"
                    className="extended-select"
                    classNamePrefix="extended-select"
                  />
                }

                {contractType === 'DOM' &&
                  <CustomSwitchField
                    className="ab-input"
                    id="pdrIsResident"
                    name="pdrIsResident"
                    initialValue={defaultValues && defaultValues.pdrIsResident !== null ? defaultValues.pdrIsResident : true}
                    defaultChecked={values.pdrIsResident}
                    label="Residente?"
                    valueLabel={values.pdrIsResident === true ? 'Sì' : 'No'}
                  />
                }
                {addOnContract && (addOnContract.code !== gasOffer.code) &&
                  <div className="form-header apply-to">
                    <FontAwesomeIcon icon={['fas', 'bolt']} className="icon" />
                    <div className="switch-input-container ab-input">
                      <p>Attiva {contractInfo.applyTo.name}</p>
                      <CustomInput
                        type="switch"
                        id="pdrAddOfferSwitch"
                        name="pdrAddOfferSwitch"
                        defaultChecked={formValues?.podContractPriceListCode && formValues.podContractPriceListCode !== null ? true : false}
                        onChange={item => {
                          if(item.target.checked === true) {
                            addOffer('E')
                          }
                          else {
                            removeOffer('E')
                            form.mutators.resetPodContractPriceListCode()
                          }
                        }}
                      />
                    </div>
                  </div>
                }
              </section>
            }

            <CustomSwitchField
              className="ab-input"
              id="billingShippingAddress"
              name="billingShippingAddress"
              label="Voglio fare una scelta sostenibile e ricevere solo la fattura digitale via email"
              valueLabel={values.billingShippingAddress === true ? 'Sì' : 'No'}
              defaultChecked={values.billingShippingAddress}
            />

            <section
              className={classnames({
                'form-section': true,
                'supply-address': true,
                'd-none': values.billingShippingAddress === true
              })}
            >
              <div className="form-header">
                <h3 className="form-title">
                  <FontAwesomeIcon icon={['fas', 'map-marker-alt']} className="icon" />
                  Indirizzo di consegna fattura cartacea
                </h3>
              </div>
              <CustomFormField
                id="atName"
                name="atName"
                type="text"
                maxLength="128"
                placeholder="Presso"
                className="ab-input"
              />
              <ExtendedSelectField
                id="toponymId"
                name="toponymId"
                options={formFieldsValues.toponymIc}
                placeholder="Via/Piazza/..."
                className="extended-select"
                classNamePrefix="extended-select"
              />
              <CustomFormField
                id="streetName"
                name="streetName"
                maxLength="128"
                type="text"
                placeholder="Indirizzo"
                className="ab-input"
              />
              <CustomFormField
                id="streetNo"
                name="streetNo"
                type="text"
                maxLength="4"
                placeholder="N° civico"
                className="ab-input"
              />
              <ExtendedAutocompleteFormField
                id="townName"
                name="townName"
                maxLength="80"
                className="extended-autocomplete"
                classNamePrefix="extended-autocomplete"
                fetchFunction={fetchTowns(accessToken)}
                placeholder="Comune"
                fieldLabel="Comune"
                minChars={2}
                getOptionLabel={option => `${option.town} (${option.province})`}
                getOptionValue={option => ({ town: option.town, province: option.province })}
                noOptionsMessage={() => 
                  <span className="autocomplete-suggestion">Indica le prime lettere del comune per trovarlo nella lista</span>
                }
              />
              <ExtendedCustomZipCodeSelect
                id="postalCode"
                name="postalCode"
                className="extended-select"
                classNamePrefix="extended-select"
                placeholder="CAP"
                fieldToListen="townName"
                fieldToListenDefaultValue={defaultValues?.townName || null}
              />
            </section>

            <div className="form-buttons">
              <Button 
                className="step-back"
                color="link"
                onClick={() => handleFormStep(SubscriptionStepsMap.CADASTRAL_STEP)}
              >
                Indietro
              </Button>
              <ButtonWithLoader
                type="submit"
                otherclasses="ab-button"
                buttonLabel="Avanti"
                onClick={handleSubmit}
                isFetching={isSubmitting}
                disabled={isSubmitting}
              />
            </div>
            <FormSpy 
              subscription={{ values: true }}
              onChange={({ values }) => {
                if(allowedStep === formStep) {
                  saveFormValues(values)
                }
              }}
            />
          </form> 
        )}
      </Form>
    </div>
  )
}

const mapStateToProps = createStructuredSelector({
  isSubmitting: selectIsSubmitting,
  contractType: selectContractType,
  cadastralDataId: selectCadastralDataId,
  bankAccountDetailId: selectBankAccountDetailId,
  energyOffer: selectEnergyOffer,
  gasOffer: selectGasOffer,
  addOnContract: selectAddOnContract,
  contractInfo: selectContractInfo,
  formFieldsValues: selectFormFieldsValues,
  defaultValues: selectSupplyPointsDefaultValues,
  accessToken: selectAccessToken,
  ongoingLastStepValues: selectOngoingLastStepValues,
  formStep: selectFormStep,
  allowedStep: selectAllowedStep
})

const mapDispatchToProps = dispatch => ({
  submitSupplyPointsForm: data => dispatch(submitSupplyPointsFormStart(data)),
  addDualContract: () => dispatch(addContract()),
  removeDualContract: () => dispatch(removeContract()),
  handleFormStep: formStep => dispatch(updateFormStep(formStep)),
  saveFormValues: values => dispatch(saveOngoingLastStepValues(values))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SupplyPointsForm)
