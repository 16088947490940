import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import CadastralForm from './CadastralForm.component'
import WithSpinner from '../../WithSpinner/WithSpinner.component'
import { fetchCadastralDefaultValuesStart } from '../../../redux/subscription/subscription.actions'
import { selectIsFetchingCadastralData, selectCadastralDataId } from '../../../redux/subscription/subscription.selectors'

const CadastralFormWithSpinner = WithSpinner(CadastralForm)

const CadastralFormContainer = () => {
  const dispatch = useDispatch()
  const cadastralDataId = useSelector(selectCadastralDataId)
  const isFetchingData = useSelector(selectIsFetchingCadastralData)
  useEffect(() => {
    if(cadastralDataId) dispatch(fetchCadastralDefaultValuesStart({ cadastralDataId }))
  }, [dispatch, cadastralDataId])

  return (
    <CadastralFormWithSpinner otherClasses="component-loader form-loader" isLoading={isFetchingData} />
  )
}

export default CadastralFormContainer
