import { createSelector } from 'reselect'

const selectModal = state => state.modal

export const selectModalVisibility = createSelector(
  [selectModal],
  modal => modal.isVisible
)

export const selectModalTitle = createSelector(
  [selectModal],
  modal => modal.modalTitle
)

export const selectModalDescription = createSelector(
  [selectModal],
  modal => modal.modalDescription
)

export const selectModalIcon = createSelector(
  [selectModal],
  modal => modal.modalIcon
)
