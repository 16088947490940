import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { Form, FormSpy } from 'react-final-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'reactstrap'

import { required, ibanCodeCheck, fiscalCodeCheck } from '../validations'
import {
  selectContractId, selectIsSubmitting, selectOngoingLastStepValues,
  selectFormFieldsValues, selectBankAccountDefaultValues, selectFormStep,
  selectAllowedStep
} from '../../../redux/subscription/subscription.selectors'
import { submitPaymentFormStart, updateFormStep, saveOngoingLastStepValues } from '../../../redux/subscription/subscription.actions'
import CustomFormField from '../CustomFormField/CustomFormField.component'
import CustomSwitchField from '../CustomSwitchField/CustomSwitchField.component'
import ButtonWithLoader from '../../ButtonWithLoader/ButtonWithLoader.component'
import { defaultBillingTimeId, defaultPaymentModeId } from './utils'
import SubscriptionStepsMap from '../../../pages/SubscriptionPage/subscriptionSteps'

const PaymentForm = ({ 
  contractId, isSubmitting, handleFormStep, defaultValues, 
  submitPaymentForm, saveFormValues, ongoingLastStepValues,
  formStep, allowedStep
}) => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [])

  const onSubmit = values => submitPaymentForm({ values })

  const formValues = defaultValues || ongoingLastStepValues

  return (
    <div className="form-container">
      <Form
        onSubmit={onSubmit}
        initialValues={{
          ...formValues,
          sameBankAccountDelegate: formValues?.sameBankAccountDelegate ?? true,
          contractId,
          billingTimeId: defaultBillingTimeId,
          paymentModeId: defaultPaymentModeId
        }}
      >
        {({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit}>
            <section className="form-section">
              <div className="form-header">
                <h3 className="form-title">
                  <FontAwesomeIcon icon={['fas', 'credit-card']} className="icon" />
                  Modalità di pagamento
                </h3>
              </div>
              <CustomFormField
                name="contractId"
                type="hidden"
              />
              <CustomFormField
                name="billingTimeId"
                type="hidden"
              />
              <CustomFormField
                name="paymentModeId"
                type="hidden"
              />
              <CustomFormField
                id="iban"
                name="iban"
                type="text"
                validate={ibanCodeCheck}
                placeholder="IBAN"
                className="ab-input"
                focusOnMount={true}
              />
              <CustomSwitchField
                className="ab-input"
                type="switch"
                id="sameBankAccountDelegate"
                name="sameBankAccountDelegate"
                label="Il titolare del C/C corrisponde all'intestatario del contratto:"
                valueLabel={values.sameBankAccountDelegate === true ? 'Sì' : 'No'}
                defaultChecked={values.sameBankAccountDelegate}
              />
            </section>
            {values.sameBankAccountDelegate === false &&
              <section className="form-section">
                <div className="form-header">
                  <h3 className="form-title">
                    <FontAwesomeIcon icon={['fas', 'address-card']} className="icon" />
                    Dati titolare C/C
                  </h3>
                </div>
                <CustomFormField
                  id="name"
                  name="name"
                  type="text"
                  validate={required}
                  placeholder="Nome"
                  className="ab-input"
                  maxLength="80"
                />
                <CustomFormField
                  id="surname"
                  name="surname"
                  type="text"
                  validate={required}
                  placeholder="Cognome"
                  className="ab-input"
                  maxLength="80"
                />
                <CustomFormField
                  id="fiscalCode"
                  name="fiscalCode"
                  type="text"
                  validate={fiscalCodeCheck}
                  placeholder="Codice fiscale"
                  className="ab-input"
                  maxLength="20"
                />
              </section>
            }

            <div className="form-buttons">
              <Button 
                className="step-back"
                color="link"
                onClick={() => handleFormStep(SubscriptionStepsMap.PROPOSAL_STEP)}
              >
                Indietro
              </Button>
              <ButtonWithLoader
                type="submit"
                otherclasses="ab-button"
                buttonLabel="Avanti"
                onClick={handleSubmit}
                isFetching={isSubmitting}
                disabled={isSubmitting}
              />
            </div>
            <FormSpy 
              subscription={{ values: true }}
              onChange={({ values }) => {
                if(formStep === allowedStep) {
                  saveFormValues(values)
                }
              }}
            />
          </form>
        )}
      </Form>
    </div>
  )
}

const mapStateToProps = createStructuredSelector({
  isSubmitting: selectIsSubmitting,
  formFieldsValues: selectFormFieldsValues,
  contractId: selectContractId,
  defaultValues: selectBankAccountDefaultValues,
  ongoingLastStepValues: selectOngoingLastStepValues,
  formStep: selectFormStep,
  allowedStep: selectAllowedStep
})

const mapDispatchToProps = dispatch => ({
  submitPaymentForm: data => dispatch(submitPaymentFormStart(data)),
  handleFormStep: formStep => dispatch(updateFormStep(formStep)),
  saveFormValues: values => dispatch(saveOngoingLastStepValues(values))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentForm)
