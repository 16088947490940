import React, { useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const GasUseModal = () => {
  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)

  return (
    <>
      <Button className="info-button" onClick={toggle}>
        <FontAwesomeIcon icon={['fas', 'question-circle']} className="icon" />
      </Button>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Cosa è la categoria uso AEEG?</ModalHeader>
        <ModalBody>
          La categoria d’uso è l’elemento che caratterizza ciascun punto di prelievo in funzione della destinazione d’uso del gas.
        </ModalBody>
      </Modal>
    </>
  )
}

export default GasUseModal
