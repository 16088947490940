import { all, call, takeLatest, put, select, delay } from 'redux-saga/effects'

import SubscriptionTypes from './subscription.types'
import { getAPIUrl } from '../../api'
import * as subscriptionActions from './subscription.actions'
import {
  selectContactId, selectContractId, selectBankAccountDetailId, selectContractName, 
  selectCadastralDataId, selectPodId, selectPdrId, selectAccessToken, selectFirebaseUser
} from './subscription.selectors'
import {
  fetchContract, getFormFieldsValues, submitForm, setupPodData,
  setupPdrData, setupPodFormData, setupPdrFormData, fetchData, gaLog
} from './subscription.utils'
import { auth, refreshFirebaseToken } from '../../firebase/firebase-utils'
import confJson from '../../api/configuration.json'
import SubscriptionStepsMap from '../../pages/SubscriptionPage/subscriptionSteps'

export function* fetchSupplyPointsDefaultValues({ payload: { podId, pdrId } }) {
  try {
    const accessToken = yield select(selectAccessToken)
    let podFormData = null
    if(podId) {
      const podData = yield call(fetchData, `${getAPIUrl('abStorePortal')}/change-suppliers/pods/${podId}`, accessToken, true)
      if(podData.error) throw podData
      podFormData = setupPodFormData(podData)
    }

    let pdrFormData = null
    if(pdrId) {
      const pdrData = yield call(fetchData, `${getAPIUrl('abStorePortal')}/change-suppliers/pdrs/${pdrId}`, accessToken, true)
      if(pdrData.error) throw pdrData
      pdrFormData = setupPdrFormData(pdrData)
    }

    yield put(subscriptionActions.fetchSupplyPointsDefaultValuesSuccess({
      ...podFormData,
      ...pdrFormData
    }))
  }
  catch(error) {
    yield put(subscriptionActions.fetchSupplyPointsDefaultValuesFailure(error))
  }
}

export function* onFetchSupplyPointsDefaultValuesStart() {
  yield takeLatest(
    SubscriptionTypes.FETCH_SUPPLY_POINTS_DEFAULT_VALUES_START,
    fetchSupplyPointsDefaultValues
  )
}

export function* fetchCadastralDefaultValues({ payload: { cadastralDataId } }) {
  try {
    const accessToken = yield select(selectAccessToken)
    const response = yield call(fetchData, `${getAPIUrl('abStorePortal')}/change-suppliers/cadastral-data/${cadastralDataId}`, accessToken, true)
    if(response.error) throw response
    yield put(subscriptionActions.fetchCadastralDefaultValuesSuccess(response))
  }
  catch(error) {
    yield put(subscriptionActions.fetchCadastralDefaultValuesFailure(error))
  }
}

export function* onFetchCadastralDefaultValuesStart() {
  yield takeLatest(
    SubscriptionTypes.FETCH_CADASTRAL_DEFAULT_VALUES_START,
    fetchCadastralDefaultValues
  )
}

export function* fetchBankAccountDefaultValues({ payload: { bankAccountDetailId } }) {
  try {
    const accessToken = yield select(selectAccessToken)
    const response = yield call(fetchData, `${getAPIUrl('abStorePortal')}/change-suppliers/bank-account-details/${bankAccountDetailId}`, accessToken, true)
    if(response.error) throw response
    yield put(subscriptionActions.fetchBankAccountDefaultValuesSuccess(response))
  }
  catch(error) {
    yield put(subscriptionActions.fetchBankAccountDefaultValuesFailure(error))
  }
}

export function* onFetchBankAccountDefaultValuesStart() {
  yield takeLatest(
    SubscriptionTypes.FETCH_BANK_ACCOUNT_DEFAULT_VALUES_START,
    fetchBankAccountDefaultValues
  )
}

export function* fetchChangeSupplierDefaultValues({ payload: { contractId } }) {
  try {
    const accessToken = yield select(selectAccessToken)
    const response = yield call(fetchData, `${getAPIUrl('abStorePortal')}/change-suppliers/${contractId}`, accessToken, true)
    if(response.error) throw response
    yield put(subscriptionActions.fetchChangeSupplierDefaultValuesSuccess(response))
  }
  catch(error) {
    yield put(subscriptionActions.fetchChangeSupplierDefaultValuesFailure(error))
  }
}

export function* onFetchChangeSupplierDefaultValuesStart() {
  yield takeLatest(
    SubscriptionTypes.FETCH_CHANGE_SUPPLIER_DEFAULT_VALUES_START,
    fetchChangeSupplierDefaultValues
  )
}

export function* fetchContactDefaultValues({ payload: { contactId } }) {
  try {
    const accessToken = yield select(selectAccessToken)
    const response = yield call(fetchData, `${getAPIUrl('abStorePortal')}/contacts/${contactId}`, accessToken, true)
    if(response.error) throw response
    yield put(subscriptionActions.fetchContactDefaultValuesSuccess(response))
  }
  catch(error) {
    yield put(subscriptionActions.fetchContactDefaultValuesFailure(error))
  }
}

export function* onFetchContactDefaultValuesStart() {
  yield takeLatest(
    SubscriptionTypes.FETCH_CONTACT_DEFAULT_VALUES_START,
    fetchContactDefaultValues
  )
}

export function* submitOtpSignature({ payload: { values: { signerId, otpCode } } }) {
  try {
    const accessToken = yield select(selectAccessToken)
    const response = yield call(submitForm, `${getAPIUrl('abStorePortal')}/change-suppliers/signers/${signerId}/signatures`, 'POST', { otpCode }, accessToken)
    if(response.error) throw response
    yield call(gaLog, "goal", SubscriptionStepsMap.OTP_STEP)
    yield put(subscriptionActions.submitOtpSignatureSuccess())
    yield put(subscriptionActions.fetchOtpSignersDataStart())
  }
  catch(error) {
    yield put(subscriptionActions.submitOtpSignatureFailure(error))
  }
}

export function* onSubmitOtpSignatureStart() {
  yield takeLatest(
    SubscriptionTypes.SUBMIT_OTP_SIGNATURE_START,
    submitOtpSignature
  )
}

export function* fetchOtpCode({ payload: { values } }) {
  try {
    const accessToken = yield select(selectAccessToken)
    const response = yield call(submitForm, `${getAPIUrl('abStorePortal')}/change-suppliers/signers/${values.signerId}/otp`, 'POST', values, accessToken)
    if(response.error) throw response
    yield put(subscriptionActions.fetchOtpCodeSuccess())
  }
  catch(error) {
    yield put(subscriptionActions.fetchOtpCodeFailure(error))
  }
}

export function* onFetchOtpCodeStart() {
  yield takeLatest(
    SubscriptionTypes.FETCH_OTP_CODE_START,
    fetchOtpCode
  )
}

export function* fetchOtpSignersData() {
  try {
    const accessToken = yield select(selectAccessToken)
    const contractId = yield select(selectContractId)
    const { signers } = yield call(fetchData, `${getAPIUrl('abStorePortal')}/change-suppliers/signers?contractId=${contractId}`, accessToken, true)
    const { signedContractLink } = yield call(fetchData, `${getAPIUrl('abStorePortal')}/change-suppliers/attachments/compiled-contract?contractId=${contractId}`, accessToken, true)
    yield put(subscriptionActions.fetchOtpSignersDataSuccess({ signers, signedContractLink }))
  }
  catch(error) {
    yield put(subscriptionActions.fetchOtpSignersDataFailure(error))
  }
}

export function* onFetchOtpSignersDataStart() {
  yield takeLatest(
    SubscriptionTypes.FETCH_OTP_SIGNERS_DATA_START,
    fetchOtpSignersData
  )
}

export function* fetchContractReviewData() {
  try {
    const accessToken = yield select(selectAccessToken)
    const contractId = yield select(selectContractId)
    const proposalData = yield call(fetchData, `${getAPIUrl('abStorePortal')}/change-suppliers/${contractId}`, accessToken, true)
    if(proposalData.error) throw proposalData

    const bankAccountDetailId = yield select(selectBankAccountDetailId)
    const paymentData = yield call(fetchData, `${getAPIUrl('abStorePortal')}/change-suppliers/bank-account-details/${bankAccountDetailId}`, accessToken, true)
    if(paymentData.error) throw proposalData

    const cadastralDataId = yield select(selectCadastralDataId)
    const cadastralData = yield call(fetchData, `${getAPIUrl('abStorePortal')}/change-suppliers/cadastral-data/${cadastralDataId}`, accessToken, true)
    if(cadastralData.error) throw proposalData

    const podId = yield select(selectPodId)
    let podData = null
    if(podId) {
      podData = yield call(fetchData, `${getAPIUrl('abStorePortal')}/change-suppliers/pods/${podId}`, accessToken, true)
      if(podData.error) throw podData
    }

    const pdrId = yield select(selectPdrId)
    let pdrData = null
    if(pdrId) {
      pdrData = yield call(fetchData, `${getAPIUrl('abStorePortal')}/change-suppliers/pdrs/${pdrId}`, accessToken, true)
      if(pdrData.error) throw pdrData
    }

    yield put(subscriptionActions.fetchContractReviewDataSuccess({
      proposalData,
      paymentData,
      cadastralData,
      podData,
      pdrData
    }))
  }
  catch(error) {
    yield put(subscriptionActions.fetchContractReviewDataFailure(error))
  }
}

export function* onFetchContractReviewDataStart() {
  yield takeLatest(
    SubscriptionTypes.FETCH_CONTRACT_REVIEW_DATA_START,
    fetchContractReviewData
  )
}

export function* submitSupplyForm({ payload: { values } }) {
  try {
    const podId = yield select(selectPodId)
    const accessToken = yield select(selectAccessToken)
    if(values.podContractPriceListCode) {
      const fetchUrl = podId === null ? `${getAPIUrl('abStorePortal')}/change-suppliers/pods` : `${getAPIUrl('abStorePortal')}/change-suppliers/pods/${podId}`
      const method = podId === null ? 'POST' : 'PUT'
      const podData = yield call(setupPodData, values)
      const podResponse = yield call(submitForm, fetchUrl, method, podData, accessToken)
      if(podResponse.error) throw podResponse
      yield put(subscriptionActions.submitPodFormSuccess(podResponse.id))
    }
    else if(podId && !values.podContractPriceListCode) {
      const deletePod = yield call(submitForm, `${getAPIUrl('abStorePortal')}/change-suppliers/pods/${podId}`, 'DELETE', null, accessToken)
      if(deletePod.error) throw deletePod
      yield put(subscriptionActions.deletePodSuccess())
    }

    const pdrId = yield select(selectPdrId)
    if(values.pdrContractPriceListCode) {
      const fetchUrl = pdrId === null ? `${getAPIUrl('abStorePortal')}/change-suppliers/pdrs` : `${getAPIUrl('abStorePortal')}/change-suppliers/pdrs/${pdrId}`
      const method = pdrId === null ? 'POST' : 'PUT'
      const pdrData = yield call(setupPdrData, values)
      const pdrResponse = yield call(submitForm, fetchUrl, method, pdrData, accessToken)
      if(pdrResponse.error) throw pdrResponse
      yield put(subscriptionActions.submitPdrFormSuccess(pdrResponse.id))
    }
    else if(pdrId && !values.pdrContractPriceListCode) {
      const deletePdr = yield call(submitForm, `${getAPIUrl('abStorePortal')}/change-suppliers/pdrs/${pdrId}`, 'DELETE', null, accessToken)
      if(deletePdr.error) throw deletePdr
      yield put(subscriptionActions.deletePdrSuccess())
    }
    const contractName = yield select(selectContractName)
    yield call(gaLog, contractName, SubscriptionStepsMap.SUPPLY_POINTS_STEP)
    yield put(subscriptionActions.submitSupplyPointsFormSuccess())
  }
  catch(error) {
    yield put(subscriptionActions.submitSupplyPointsFormFailure(error))
  }
}

export function* onSubmitSupplyFormStart() {
  yield takeLatest(
    SubscriptionTypes.SUBMIT_SUPPLY_POINTS_FORM_START,
    submitSupplyForm
  )
}

export function* submitCadastralForm({ payload: { values } }) {
  try {
    const accessToken = yield select(selectAccessToken)
    const cadastralDataId = yield select(selectCadastralDataId)
    const fetchUrl = cadastralDataId === null ? (
      `${getAPIUrl('abStorePortal')}/change-suppliers/cadastral-data`
    ) : (
      `${getAPIUrl('abStorePortal')}/change-suppliers/cadastral-data/${cadastralDataId}`
    )
    const method = cadastralDataId === null ? 'POST' : 'PUT'
    const response = yield call(submitForm, fetchUrl, method, values, accessToken)
    if(response.error) throw response
    const contractName = yield select(selectContractName)
    yield call(gaLog, contractName, SubscriptionStepsMap.CADASTRAL_STEP)
    yield put(subscriptionActions.submitCadastralFormSuccess(response.id))
  }
  catch(error) {
    yield put(subscriptionActions.submitCadastralFormFailure(error))
  }
}

export function* onSubmitCadastralFormStart() {
  yield takeLatest(
    SubscriptionTypes.SUBMIT_CADASTRAL_FORM_START,
    submitCadastralForm
  )
}

export function* submitPaymentForm({ payload: { values } }) {
  try {
    const accessToken = yield select(selectAccessToken)
    const bankAccountDetailId = yield select(selectBankAccountDetailId)
    const method = bankAccountDetailId === null ? 'POST' : 'PUT'
    const fetchUrl = bankAccountDetailId === null ? (
      `${getAPIUrl('abStorePortal')}/change-suppliers/bank-account-details`
    ) : (
      `${getAPIUrl('abStorePortal')}/change-suppliers/bank-account-details/${bankAccountDetailId}`
    )
    const response = yield call(submitForm, fetchUrl, method, values, accessToken)
    if(response.error) throw response
    const contractName = yield select(selectContractName)
    yield call(gaLog, contractName, SubscriptionStepsMap.PAYMENT_STEP)
    yield put(subscriptionActions.submitPaymentFormSuccess(response.id))
  }
  catch(error) {
    yield put(subscriptionActions.submitPaymentFormFailure(error))
  }
}

export function* onSubmitPaymentFormStart() {
  yield takeLatest(
    SubscriptionTypes.SUBMIT_PAYMENT_FORM_START,
    submitPaymentForm
  )
}

export function* submitProposalForm({ payload: { values } }) {
  try {
    const accessToken = yield select(selectAccessToken)
    const contractId = yield select(selectContractId)
    const method = contractId === null ? 'POST' : 'PUT'
    const fetchUrl = contractId === null ? (
      `${getAPIUrl('abStorePortal')}/change-suppliers`
    ) : (
      `${getAPIUrl('abStorePortal')}/change-suppliers/${contractId}`
    )
    const response = yield call(submitForm, fetchUrl, method, values, accessToken)
    if(response.error) throw response
    const contractName = yield select(selectContractName)
    yield call(gaLog, contractName, SubscriptionStepsMap.PROPOSAL_STEP)
    yield put(subscriptionActions.submitProposalFormSuccess(response.id))
  }
  catch(error) {
    yield put(subscriptionActions.submitProposalFormFailure(error))
  }
}

export function* onSubmitProposalFormStart() {
  yield takeLatest(
    SubscriptionTypes.SUBMIT_PROPOSAL_FORM_START,
    submitProposalForm
  )
}

export function* submitContactForm({ payload: { values } }) {
  try {
    const accessToken = yield select(selectAccessToken)
    const contactId = yield select(selectContactId)
    const method = contactId === null ? 'POST' : 'PUT'
    const fetchUrl = contactId === null ? (
      `${getAPIUrl('abStorePortal')}/contacts`
    ) : (
      `${getAPIUrl('abStorePortal')}/contacts/${contactId}`
    )
    const response = yield call(submitForm, fetchUrl, method, values, accessToken)
    if(response.error) throw response
    const contractName = yield select(selectContractName)
    yield call(gaLog, contractName, SubscriptionStepsMap.CONTACT_STEP)
    yield put(subscriptionActions.submitContactFormSuccess(response.id))
  }
  catch(error) {
    yield put(subscriptionActions.submitContactFormError(error))
  }
}

export function* onSubmitContactFormStart() {
  yield takeLatest(
    SubscriptionTypes.SUBMIT_CONTACT_FORM_START,
    submitContactForm
  )
}

export function* fetchContractData({ payload: { e, g, contactId, secureCode } }) {
  try {
    const { firebaseCredentials: { email, password } } = confJson
    const { user } = yield auth.signInWithEmailAndPassword(email, password)
    const accessToken = yield user.getIdToken()
    const abUser = yield call(fetchData, `${getAPIUrl('abStorePortal')}/login`, accessToken, true)
    let defaultEntitiesId = {}
    if(contactId && secureCode) {
      const response = yield call(fetchData, `${getAPIUrl('abStorePortal')}/contacts/${contactId}/${secureCode}`, accessToken, true)
      if(response.error) throw response
      defaultEntitiesId = response
    }

    const contractResponse = yield call(fetchContract, accessToken, e, g)
    if(contractResponse.error) throw contractResponse

    const formValuesResponse = yield(call(getFormFieldsValues, accessToken, e, g))
    if(formValuesResponse.error) throw formValuesResponse

    yield put(subscriptionActions.fetchContractSuccess({
      contractInfo: contractResponse.data,
      formFieldsValues: formValuesResponse.data,
      firebaseUser: user,
      abUser,
      accessToken,
      defaultEntitiesId
    }))
  }
  catch(error) {
    yield put(subscriptionActions.fetchContractFailure(error))
  }
}

export function* onFetchContractStart() {
  yield takeLatest(
    SubscriptionTypes.FETCH_CONTRACT_START,
    fetchContractData
  )
}

export function* refreshToken() {
  try {
    const firebaseUser = yield select(selectFirebaseUser)
    const newToken = yield call(refreshFirebaseToken, firebaseUser)
    yield put(subscriptionActions.userRefreshTokenSuccess({ newToken }))
  }
  catch(error) {
    yield put(subscriptionActions.userRefreshTokenFailure(error))
  }
}

//Update user token every 30 minutes
export function* refreshUserTokenPeriodically() {
  while (true) {
    yield delay(600000)
    const userToken = yield select(selectAccessToken)
    if(userToken) {
      yield call(refreshToken)
    }
  }
}

export function* subscriptionSagas() {
  yield all([
    call(onFetchContractStart),
    call(onSubmitContactFormStart),
    call(onSubmitProposalFormStart),
    call(onSubmitPaymentFormStart),
    call(onSubmitCadastralFormStart),
    call(onSubmitSupplyFormStart),
    call(onFetchOtpSignersDataStart),
    call(onFetchOtpCodeStart),
    call(onSubmitOtpSignatureStart),
    call(onFetchContactDefaultValuesStart),
    call(onFetchChangeSupplierDefaultValuesStart),
    call(onFetchBankAccountDefaultValuesStart),
    call(onFetchCadastralDefaultValuesStart),
    call(onFetchSupplyPointsDefaultValuesStart),
    call(onFetchContractReviewDataStart),
    call(refreshUserTokenPeriodically)
  ])
}
