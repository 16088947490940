import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { ReactComponent as Logo } from '../../assets/svg/logo-ab.svg'

const Footer = () => (
  <footer>
    <Container>
      <Row className="align-items-center">
        <Col xs="12" sm="6" md="3" className="mb-4 mb-md-0">
          <div className="logo">
            <Logo />
          </div>
          <div>&copy; ABenergie S.p.A. - P.IVA 03308940166</div>
        </Col>
        <Col xs="12" sm="6" md="3" className="mb-4 mb-md-0">
          <div className="call-to-action">
            <FontAwesomeIcon icon={['fas', 'phone-alt']} className="mr-1" />
            <div className="text">
              <p className="mb-1"><strong>800 984 400</strong> Numero verde (da rete fissa)</p>
              <p className="mb-0"><strong>+39 035 2819299</strong> (da rete mobile)</p>
            </div>
          </div>
        </Col>
        <Col xs="12" sm="6" md="3" className="mb-4 mb-md-0">
          <div className="call-to-action">
            <FontAwesomeIcon icon={['fas', 'envelope']} className="mr-1" />
            <div className="text">
              <a className="blue" href="mailto:info@abenergie.it"><strong>info@abenergie.it</strong></a>
            </div>
          </div>
        </Col>
        <Col xs="12" sm="6" md="3" className="mt-3 mt-md-0">
          <ul className="inline">
            <li>
              <a href="https://it-it.facebook.com/ABenergie/" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={['fab', 'facebook-f']} />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/abenergieofficial/" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={['fab', 'instagram']} />
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/abenergie" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={['fab', 'linkedin']} />
              </a>
            </li>
            <li>
              <a href="https://www.youtube.com/channel/UCjtzHLHklYE5wK7Ex1SstEQ" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={['fab', 'youtube']} />
              </a>
            </li>
          </ul>
        </Col>

      </Row>
    </Container>
  </footer>
)

export default Footer
