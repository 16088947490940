import React from 'react'
import { Field } from 'react-final-form'
import { FormGroup, FormFeedback, Label } from 'reactstrap'
import classnames from 'classnames'
import Select from 'react-select'

import ExtendedSelectFormFieldStyle from './style'

const ExtendedSelectField = ({ children, ...props }) => {
  return (
    <ExtendedSelectFormFieldStyle>
      <FormGroup>
        <Field {...props}>
          {({ isMulti = false, placeholder, input: { value, onChange, onBlur }, meta: { error, touched }, options, className, ...input }) => (
            <>
              <Select
                className={`${className}${error && touched ? ' is-invalid' : (!error && touched ? ' is-valid' : '')}`}
                value={value}
                isMulti={isMulti}
                options={options}
                onChange={value => onChange(value)}
                onBlur={onBlur}
                placeholder={placeholder}
                {...input}
              />
              {placeholder &&
                <Label for={input.name} className={`${value ? 'shrink' : ''} form-input-label`}>{placeholder}</Label>
              }
              <FormFeedback className={classnames({ 'd-flex': error && touched })}>{error}</FormFeedback>
            </>
          )}
        </Field>
        {props.children}
      </FormGroup>
    </ExtendedSelectFormFieldStyle>
  )
}

export default ExtendedSelectField
