import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import ProposalForm from './ProposalForm.component'
import WithSpinner from '../../WithSpinner/WithSpinner.component'
import { fetchChangeSupplierDefaultValuesStart } from '../../../redux/subscription/subscription.actions'
import { selectIsFetchingProposalData, selectContractId } from '../../../redux/subscription/subscription.selectors'

const ProposalFormWithSpinner = WithSpinner(ProposalForm)

const ProposalFormContainer = () => {
  const dispatch = useDispatch()
  const contractId = useSelector(selectContractId)
  const isFetchingData = useSelector(selectIsFetchingProposalData)
  useEffect(() => {
    //Load form default values if opening an old contract
    if(contractId) dispatch(fetchChangeSupplierDefaultValuesStart({ contractId }))
  }, [contractId, dispatch])

  return (
    <ProposalFormWithSpinner otherClasses="component-loader form-loader" isLoading={isFetchingData} />
  )
}

export default ProposalFormContainer
